import React, { useState, useEffect } from 'react';
import ButtonPrimary from '../components/buttons/ButtonPrimary';
import { CommunityCard, BrochureCard } from '../components/community';
import AddNewIcon from '../components/icons/AddNewIcon';
import Layout, { SizeConstrained } from '../components/layout/Layout';
import H1 from '../components/typography/H1';
import H2 from '../components/typography/H2';
import { useQuery } from '@apollo/client';
import { useNavigate, useOutletContext } from 'react-router-dom';
import CreateProjectModal from '../components/modals/CreateProjectModal';
import LoadingSpinner from '../components/LoadingSpinner';
import { GET_COMMUNITIES_BY_USER } from '../graphql/queries/communities';
import { CREATE_USER } from '../constants/contentfulActions';
import { extractAllCommunitiesData } from '../graphql/helpers';
import { GET_CURRENT_USER_ID } from '../graphql/queries/users';
import Error from '../components/Error';
import { useContentful } from '../hooks/useContentful';

function MyCommunities() {
  const navigate = useNavigate();
  const { currentUser } = useOutletContext();
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [createProjectOpen, setCreateProjectOpen] = useState(false);
  const [dispatchCreateUser, createUserState] = useContentful();

  const { error: userIdError, loading: userIdLoading } = useQuery(
    GET_CURRENT_USER_ID,
    {
      variables: { email: currentUser.email },
      onCompleted: async (data) => {
        if (!data.userCollection.items.length) {
          await dispatchCreateUser({
            type: CREATE_USER,
            payload: {
              email: currentUser.email,
            },
          });
        } else {
          setUserId(data.userCollection.items[0].sys.id);
        }
        setLoading(false);
      },
    }
  );
  const [communities, setCommunities] = useState([]);
  const { error, refetch } = useQuery(GET_COMMUNITIES_BY_USER, {
    variables: { email: currentUser.email },
    errorPolicy: 'ignore',
    skip: !userId,
    onCompleted: (data) => {
      setLoading(false);
      setCommunities(extractAllCommunitiesData(data));
    },
  });

  useEffect(() => {
    if (createUserState.data != null) {
      setUserId(createUserState.data.sys.id);
    }
    navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUserState.success]);

  if (loading || userIdLoading) {
    return <LoadingSpinner />;
  }

  if (userIdError || error) {
    return <Error />;
  }

  return (
    <>
      <Layout>
        <CreateProjectModal
          open={createProjectOpen}
          setOpen={setCreateProjectOpen}
        />
        <SizeConstrained
          topMost={true}
          className="mb-10 px-6 flex items-center justify-between"
        >
          <H1>Projects</H1>
          <ButtonPrimary
            onClick={() => {
              setCreateProjectOpen(true);
            }}
          >
            Add new project
            <AddNewIcon className="ml-4" />
          </ButtonPrimary>
        </SizeConstrained>
        <SizeConstrained className="mb-10 px-6 lg:px-16">
          <H2>Brochures</H2>
          {communities.length ? (
              <div className="">
                {communities
                  .filter((community) => community.isBrochure)
                  .map((community) => (
                    <BrochureCard
                      key={community.id}
                      communityData={community}
                      refetch={refetch}
                      currentUser={currentUser}
                      userId={userId}
                    />
                  ))}
              </div>
          ) : (
            <div className="mt-10 flex h-screen max-h-32 w-full items-center justify-center">
              <div className="flex flex-col items-center text-center justify-center">
                <p className="text-aw-blue-900 text-2xl font-aw-sans">
                  You have no active brochures.
                </p>
                <p className="text-aw-blue-900 text-2xl font-aw-sans">
                  Click the button above to create&nbsp;one.
                </p>
              </div>
            </div>
          )}
        </SizeConstrained>
        <SizeConstrained className="px-6 lg:px-16">
          <H2>Community Microsites</H2>
          <div className="mt-5">
            {communities.length ? (
              <div className="">
                {communities
                  .filter((community) => !community.isBrochure)
                  .map((community) => (
                    <CommunityCard
                      key={community.id}
                      communityData={community}
                      refetch={refetch}
                      currentUser={currentUser}
                      userId={userId}
                    />
                  ))}
              </div>
            ) : (
              <div className="mt-10 flex h-screen max-h-32 w-full items-center justify-center">
                <div className="flex flex-col items-center text-center justify-center">
                  <p className="text-aw-blue-900 text-2xl font-aw-sans">
                    You have no active communities.
                  </p>
                  <p className="text-aw-blue-900 text-2xl font-aw-sans">
                    Click the button above to create&nbsp;one.
                  </p>
                </div>
              </div>
            )}
          </div>
        </SizeConstrained>
      </Layout>
    </>
  );
}

export default MyCommunities;
