export const updateCommunityHandler = async (response, params, environment) => {
  const {
    id,
    rooms,
    name,
    banner,
    recommendations,
    addOns,
    hardwareLevels,
    cabinetLevels,
    pdfName,
    pdfShowCover,
    pdfShowDate,
    pdfShowCommunityName,
    pdfCoverImage,
    pdfSelectedExpectationSheets,
    pdfGallery,
  } = params.payload.communityData;

  // Update all hardware levels
  for (const key of Object.keys(hardwareLevels)) {
    const hardwareLevelEntry = await environment.getEntry(key);
    hardwareLevelEntry.fields.title = {
      'en-US': hardwareLevels[key].title,
    };
    hardwareLevelEntry.fields.description = {
      'en-US': hardwareLevels[key].description,
    };
    hardwareLevelEntry.fields.products = {
      'en-US': hardwareLevels[key].ids.map((productId) => ({
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: productId,
        },
      })),
    };
    await hardwareLevelEntry.update();
    const updatedLevel = await environment.getEntry(key);
    await updatedLevel.publish();
  }

  // Update all cabinet level
  for (const key of Object.keys(cabinetLevels)) {
    const cabinetLevelEntry = await environment.getEntry(key);
    cabinetLevelEntry.fields.title = {
      'en-US': cabinetLevels[key].title,
    };
    cabinetLevelEntry.fields.description = {
      'en-US': cabinetLevels[key].description,
    };
    cabinetLevelEntry.fields.products = {
      'en-US': cabinetLevels[key].ids.map((productId) => ({
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: productId,
        },
      })),
    };
    await cabinetLevelEntry.update();
    const updatedLevel = await environment.getEntry(key);
    await updatedLevel.publish();
  }

  // recreate the hardware levels field array to sync levels including deletions
  const newHardwareLevelsArr = Object.keys(hardwareLevels).map((key) => {
    return {
      sys: {
        type: 'Link',
        linkType: 'Entry',
        id: key,
      },
    };
  });

  // recreate the cabinet levels field array to levels including deletions
  const newCabinetLevelsArr = Object.keys(cabinetLevels).map((key) => {
    return {
      sys: {
        type: 'Link',
        linkType: 'Entry',
        id: key,
      },
    };
  });

  // Update all rooms
  for (const room of rooms) {
    const roomEntry = await environment.getEntry(room.id);
    roomEntry.fields.name = { 'en-US': room.name };
    roomEntry.fields.image = {
      'en-US': {
        sys: {
          type: 'Link',
          linkType: 'Asset',
          id: room.image.id,
        },
      },
    };
    roomEntry.fields.headerImage = {
      'en-US': {
        sys: {
          type: 'Link',
          linkType: 'Asset',
          id: room.headerImage.id,
        },
      },
    };
    roomEntry.fields.products = {
      'en-US': room.products.map((product) => ({
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: product,
        },
      })),
    };
    await roomEntry.update();
    const updatedRoom = await environment.getEntry(room.id);
    await updatedRoom.publish();
  }

  // recreate the rooms field array with any order changes
  const newRoomsArr = rooms.map((room) => {
    return {
      sys: {
        type: 'Link',
        linkType: 'Entry',
        id: room.id,
      },
    };
  });

  // Update all recommendations
  if (recommendations.length) {
    for (const recommendation of recommendations) {
      const recommendationEntry = await environment.getEntry(recommendation.id);
      recommendationEntry.fields.product = {
        'en-US': {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: recommendation.product.id,
          },
        },
      };
      recommendationEntry.fields.image = {
        'en-US': {
          sys: {
            type: 'Link',
            linkType: 'Asset',
            id: recommendation.image.id,
          },
        },
      };
      await recommendationEntry.update();
      const updatedRecommendationEntry = await environment.getEntry(
        recommendation.id
      );
      await updatedRecommendationEntry.publish();
    }
  }

  const newRecommendationsArr = recommendations.map((recommendation) => {
    return {
      sys: {
        type: 'Link',
        linkType: 'Entry',
        id: recommendation.id,
      },
    };
  });

  // update all add-ons in parallel
  if (addOns.length) {
    for (const addOn of addOns) {
      const addOnEntry = await environment.getEntry(addOn.id);
      addOnEntry.fields.title = { 'en-US': addOn.title };
      addOnEntry.fields.description = {
        'en-US': addOn.description.json,
      };
      addOnEntry.fields.videoUrl = { 'en-US': addOn.videoUrl };
      addOnEntry.fields.image = {
        'en-US': {
          sys: {
            type: 'Link',
            linkType: 'Asset',
            id: addOn.image.id,
          },
        },
      };
      await addOnEntry.update();
      const updatedAddOnEntry = await environment.getEntry(addOn.id);
      await updatedAddOnEntry.publish();
    }
  }

  // Update community entry and publish changes
  const communityEntryToUpdate = await environment.getEntry(id);
  communityEntryToUpdate.fields.name = { 'en-US': name };
  communityEntryToUpdate.fields.banner = {
    'en-US': {
      sys: {
        type: 'Link',
        linkType: 'Asset',
        id: banner.id,
      },
    },
  };
  communityEntryToUpdate.fields.rooms = {
    'en-US': newRoomsArr,
  };

  communityEntryToUpdate.fields.recommendations = {
    'en-US': newRecommendationsArr,
  };

  communityEntryToUpdate.fields.hardwareLevels = {
    'en-US': newHardwareLevelsArr,
  };
  communityEntryToUpdate.fields.cabinetLevels = {
    'en-US': newCabinetLevelsArr,
  };

  // PDF Fields
  communityEntryToUpdate.fields.pdfName = { 'en-US': pdfName };
  communityEntryToUpdate.fields.pdfShowCover = { 'en-US': pdfShowCover };
  communityEntryToUpdate.fields.pdfShowDate = { 'en-US': pdfShowDate };
  communityEntryToUpdate.fields.pdfShowCommunityName = {
    'en-US': pdfShowCommunityName,
  };
  communityEntryToUpdate.fields.pdfCoverImage =
    pdfCoverImage !== null
      ? {
          'en-US': {
            sys: {
              type: 'Link',
              linkType: 'Asset',
              id: pdfCoverImage.id,
            },
          },
        }
      : null;
  communityEntryToUpdate.fields.pdfSelectedExpectationSheets = {
    'en-US': pdfSelectedExpectationSheets,
  };
  const newPdfGalleryArr = pdfGallery.length
    ? pdfGallery.map((image) => {
        return {
          sys: {
            type: 'Link',
            linkType: 'Asset',
            id: image.image.id,
          },
        };
      })
    : [];
  communityEntryToUpdate.fields.pdfGallery = {
    'en-US': newPdfGalleryArr,
  };

  await communityEntryToUpdate.update();
  const updatedCommunityEntry = await environment.getEntry(id);
  await updatedCommunityEntry.update().then((entry) => entry.publish());

  response = await environment.getEntry(id);
  return {
    response,
  };
};
