import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import GeneratorBreadcrumbs from '../components/generator/GeneratorBreadcrumbs';
import Layout, { SizeConstrained } from '../components/layout/Layout';
import { useOutletContext } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../components/LoadingSpinner';
import Error from '../components/Error';
import { GET_ALL_PRODUCTS } from '../graphql/queries/products';
import { GET_SINGLE_COMMUNITY } from '../graphql/queries/communities';
import { GET_BUILDERS } from '../graphql/queries/builders';
import { COMMUNITY_DATA_TEMPLATE } from '../constants/communityData';
import {
  extractBuildersData,
  extractSingleCommunitiesData,
  synchronizeOrphanedLevelsToUncategorized,
} from '../graphql/helpers';
import { useParams } from 'react-router-dom';
import { useCommunityRoomsQuery } from '../graphql/query-hooks/useCommunityRoomsQuery';
import { useCommunityCabinetLevelsQuery } from '../graphql/query-hooks/useCommunityCabinetLevelsQuery';
import { useCommunityHardwareLevelsQuery } from '../graphql/query-hooks/useCommunityHardwareLevelsQuery';
import { useCommunityPdfQuery } from '../graphql/query-hooks/useCommunityPdfQuery';

// NOTE: The PdfGenerator uses many of the same data/queries. Any changes to
// data structure should also be done for the PdfGenerator views.

function Generator() {
  const { id } = useParams();
  const { currentUser } = useOutletContext();
  const [allBuildersData, setAllBuildersData] = useState([]);
  const [draftSaveCount, setDraftSaveCount] = useState(0);
  // removedRecommendations is used to keep track of recommendations that have been
  // removed due to unselecting a product in the room builder
  const [removedRecommendations, setRemovedRecommendations] = useState([]);
  const [initialCommunityName, setInitialCommunityName] = useState('');
  const [isCommunityQueriesComplete, setIsCommunityQueriesComplete] = useState(false);
  const [isCommunityLoaded, setIsCommunityLoaded] = useState(false);
  const [communityData, setCommunityData] = useState({
    ...COMMUNITY_DATA_TEMPLATE
  });
  const {
    loading,
    error,
    data,
    refetch: refetchCommunityData,
  } = useQuery(GET_SINGLE_COMMUNITY, {
    variables: { id },
    skip: !id,
  });

  const { data: communityRoomsData } = useCommunityRoomsQuery(id, (roomData, rawData) => {
    const roomsTotal = rawData?.community?.roomsCollection?.total;
    setCommunityData(prevState => ({
      ...prevState,
      rooms: roomData?.rooms,
      uniqueCabinetSelections: roomData?.uniqueCabinetSelections,
      uniqueHardwareSelections: roomData?.uniqueHardwareSelections,
      roomsTotal: roomsTotal !== undefined ? roomsTotal : prevState?.roomsTotal
    }));
  });
  const { data: communityCabinetLevelData } = useCommunityCabinetLevelsQuery(id, (cabinetLevelData, rawData) => {
    const total = rawData?.community?.cabinetLevelsCollection?.total;
    setCommunityData(prevState => ({
      ...prevState,
      cabinetLevels: cabinetLevelData?.cabinetLevels,
      cabinetLevelsTotal: total !== undefined ? total : prevState?.cabinetLevelsTotal
    }));
  });
  const { data: communityHardwareLevelData } = useCommunityHardwareLevelsQuery(id, (hardwareLevelData, rawData) => {
    const total = rawData?.community?.hardwareLevelsCollection?.total;
    setCommunityData(prevState => ({
      ...prevState,
      hardwareLevels: hardwareLevelData?.hardwareLevels,
      hardwareLevelsTotal: total !== undefined ? total : prevState?.hardwareLevelsTotal
    }));
  });

  const { data: communityPdfData } = useCommunityPdfQuery(
    id,
    (pdfData, rawData) => {
      setCommunityData((prevState) => ({
        ...prevState,
        pdfName: pdfData?.pdfName,
        pdfShowCover: pdfData?.pdfShowCover,
        pdfShowDate: pdfData?.pdfShowDate,
        pdfShowCommunityName: pdfData?.pdfShowCommunityName,
        pdfCoverImage: pdfData?.pdfCoverImage,
        pdfSelectedExpectationSheets: pdfData?.pdfSelectedExpectationSheets,
        pdfGallery: pdfData?.pdfGallery,
      }));
    }
  );
  useEffect(()=>{
    // Sets community as fully loaded or not as API calls finish.
    const roomsLoaded = (communityData?.roomsTotal !== null) && (communityData?.roomsTotal <= communityData?.rooms?.length);
    const cabinetLevelsLoaded = (communityData?.cabinetLevelsTotal !== null) && (communityData?.cabinetLevelsTotal <= Object.values(communityData?.cabinetLevels)?.length);
    const hardwareLevelsLoaded = (communityData?.hardwareLevelsTotal !== null) && (communityData?.hardwareLevelsTotal <= Object.values(communityData?.hardwareLevels)?.length);

    const isLoadedResult = !loading
      && communityData?.communityDetailsLoaded
      && roomsLoaded
      && cabinetLevelsLoaded
      && hardwareLevelsLoaded;

    setIsCommunityQueriesComplete(isLoadedResult)
  }, [
    communityData.rooms,
    communityData.roomsTotal,
    communityData.cabinetLevels,
    communityData.cabinetLevelsTotal,
    communityData.hardwareLevels,
    communityData?.hardwareLevelsTotal,
    communityData?.communityDetailsLoaded,
    loading
  ]);

  useEffect(()=>{
    // Community fully loaded, final synchronization and data checks.
    if(!isCommunityQueriesComplete) { return; }

    const { cabinetLevelsObj, hardwareLevelsObj } = synchronizeOrphanedLevelsToUncategorized(
      communityData.uniqueHardwareSelections,
      communityHardwareLevelData,
      communityData.uniqueCabinetSelections,
      communityCabinetLevelData
    );

    setCommunityData(prevState => ({
      ...prevState,
      cabinetLevelsObj: cabinetLevelsObj,
      hardwareLevelsObj: hardwareLevelsObj
    }));
    setIsCommunityLoaded(true);

  }, [
    isCommunityQueriesComplete,
    communityCabinetLevelData,
    communityHardwareLevelData,
    communityData.uniqueCabinetSelections,
    communityData.uniqueHardwareSelections
  ]);

  const {
    loading: productsLoading,
    error: productsError,
    data: productsData,
  } = useQuery(GET_ALL_PRODUCTS);
  const {
    loading: buildersLoading,
    error: buildersError,
    data: buildersData,
  } = useQuery(GET_BUILDERS, {
    skip: !data,
    variables: { email: currentUser.email },
  });

  useEffect(() => {
    if (data) {
      const formattedData = extractSingleCommunitiesData(data);

      setInitialCommunityName(formattedData.name);

      setCommunityData(prevState => {
        return {
          ...prevState,
          ...formattedData,
          id,
          communityDetailsLoaded: true,
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (buildersData) {
      const formattedBuildersData = extractBuildersData(buildersData);
      setAllBuildersData(formattedBuildersData);
    }
  }, [buildersData]);

  const isLoading = productsLoading || buildersLoading || loading || !isCommunityLoaded;
  const hasError = productsError || buildersError || error;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (hasError) {
    return <Error />;
  }

  const titleCaseToCamelCase = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word, index) =>
        index === 0 ? word : word[0].toUpperCase() + word.substr(1)
      )
      .join('');
  };

  const productsList = productsData.productFamilyCollection.items.map(
    (family) => {
      const productLines = family.linkedFrom.productLineCollection.items.map(
        (line) => {
          const products = [...line.linkedFrom.productCollection.items]
            // filter out duplicates by productCode
            // this is just a failsafe that will account for any products entries that have been entered twice on accident
            .filter((product, index, self) => {
              return (
                index ===
                self.findIndex((t) => t.productCode === product.productCode)
              );
            })
            .sort((a, b) => {
              if (a.productCode < b.productCode) {
                return -1;
              }
              if (a.productCode > b.productCode) {
                return 1;
              }
              return 0;
            })
            .map((product) => {
              return {
                productCode: product.productCode,
                image: product.image.url,
                wood: product.wood,
                finish: product.finish,
                id: product.sys.id,
              };
            });

          return {
            name: line.name,
            products,
            id: line.sys.id,
          };
        }
      );

      return {
        name: titleCaseToCamelCase(family.name),
        formattedName: family.name,
        productLines,
      };
    }
  );

  const builderImageTag = communityData?.builder?.builderTag;

  const generatorContext = {
    communityData,
    setCommunityData,
    currentUser,
    productsList,
    allBuildersData,
    refetchCommunityData,
    initialCommunityName,
    builderImageTag,
    removedRecommendations,
    setRemovedRecommendations,
    draftSaveCount,
    setDraftSaveCount,
    isCommunityLoaded,
  };
  return (
    <Layout>
      <SizeConstrained topMost={true}>
        <div className="mb-16">
          <GeneratorBreadcrumbs />
        </div>
      </SizeConstrained>
      <Outlet context={generatorContext} />
    </Layout>
  );
}

export default Generator;
