import React, { useEffect, useState } from 'react';
import SpeedbumpWithResult from '../modals/SpeedbumpWithResult';
import MenuItemButton from './MenuItemButton';

function DeleteCommunityButton({
  children,
  open,
  active = false,
  setOpen,
  communityData,
  deleteCommunityState,
  handleDeleteCommunity,
  refetch,
}) {
  const [hasError, setHasError] = useState(false);
  const { name: communityName } = communityData;

  useEffect(() => {
    if (deleteCommunityState.success) {
      refetch();
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCommunityState.success]);

  // abstracting the error flag one step allows the error
  // to be reset when the modal is closed
  // this is a workaround for the error state not being reset in the hook

  return (
    <>
      <MenuItemButton
        onClick={() => {
          setHasError(false);
          setOpen(true);
        }}
        active={active}
      >
        {children}
      </MenuItemButton>
      <SpeedbumpWithResult
        open={open}
        loading={deleteCommunityState.loading}
        setOpen={setOpen}
        success={deleteCommunityState.success}
        onConfirm={() => handleDeleteCommunity()}
        onClose={() => setOpen(false)}
        confirmTitle={`Delete ${communityName} microsite`}
        confirmBody={`Are you sure you want to delete the ${communityName} microsite? This action cannot be undone.`}
        confirmBtnText="Delete"
        error={hasError}
        errorTitle="Something went wrong"
        errorBody="There was an error deleting this microsite. Please try again."
        errorBtnText="Close"
      />
    </>
  );
}

export default DeleteCommunityButton;
