/**
 * Updates and publishes an entry.
 *
 * @param   {Entry}  entry  Contnetful entry to update and publish.
 *
 * @return  {Promise}        A promise that resolves when the entry is updated and published.
 */
const updateAndPublish = async (entry) => {
  await entry.update().then((updatedEntry) => {
    return updatedEntry.publish();
  });
};

/**
 * Creates an asset link object for a given asset.
 *
 * @param {Object} asset - The asset object.
 * @returns {Object} - The asset link object.
 */
const createAssetLink = (asset) => {
  return asset?.sys?.id
    ? {
        'en-US': {
          sys: {
            type: 'Link',
            linkType: 'Asset',
            id: asset.sys.id,
          },
        },
      }
    : { 'en-US': undefined };
};

const cloneRoom = async (room, environment) => {
  const foundRoom = await environment.getEntry(room.sys.id);

  try {
    const originalFields = { ...foundRoom.fields };
    const newFields = {
      name: { 'en-US': originalFields.name?.['en-US'] ?? '' },
      image: createAssetLink(originalFields.image?.['en-US']),
      headerImage: createAssetLink(originalFields.headerImage?.['en-US']),
      products: { 'en-US': originalFields?.products?.['en-US'] ?? [] },
    };

    let newRoom = await environment.createEntry('room', { fields: newFields });
    await updateAndPublish(newRoom);
    newRoom = await environment.getEntry(newRoom.sys.id);

    return newRoom;
  } catch (error) {
    console.error('Error cloning room:', error);
    throw error;
  }
};

const cloneCabinetLevel = async (cabinetLevel, environment) => {
  const foundCabLevel = await environment.getEntry(cabinetLevel.sys.id);
  const originalFields = { ...foundCabLevel.fields };
  const newFields = {
    title: {
      'en-US': originalFields?.title?.['en-US'] ?? '',
    },
    description: {
      'en-US': originalFields?.description?.['en-US'] ?? '',
    },
    products: {
      'en-US': originalFields?.products?.['en-US']
        ? originalFields?.products?.['en-US']
        : [],
    },
  };

  let newCabinetLevel = await environment.createEntry('level', {
    fields: newFields,
  });
  await updateAndPublish(newCabinetLevel);
  newCabinetLevel = await environment.getEntry(newCabinetLevel.sys.id);

  return newCabinetLevel;
};

const cloneHardwareLevel = async (hardwareLevel, environment) => {
  const foundHardwareLevel = await environment.getEntry(hardwareLevel.sys.id);
  const originalFields = { ...foundHardwareLevel.fields };
  const newFields = {
    title: {
      'en-US': originalFields?.title?.['en-US'] ?? '',
    },
    description: {
      'en-US': originalFields?.description?.['en-US'] ?? '',
    },
    products: {
      'en-US': originalFields?.products?.['en-US']
        ? originalFields?.products?.['en-US']
        : [],
    },
  };

  let newHardwareLevel = await environment.createEntry('level', {
    fields: newFields,
  });
  await updateAndPublish(newHardwareLevel);
  newHardwareLevel = await environment.getEntry(newHardwareLevel.sys.id);

  return newHardwareLevel;
};

const cloneRecommendation = async (recommendation, environment) => {
  const foundRecommendation = await environment.getEntry(recommendation.sys.id);
  const originalFields = { ...foundRecommendation.fields };
  let newRecommendation = await environment.createEntry('recommendedProduct', {
    fields: originalFields,
  });
  await updateAndPublish(newRecommendation);
  newRecommendation = await environment.getEntry(newRecommendation.sys.id);

  return newRecommendation;
};

const cloneAddon = async (addon, environment) => {
  const foundAddon = await environment.getEntry(addon.sys.id);
  const originalFields = { ...foundAddon.fields };
  let newFields = {
    title: originalFields.title?.['en-US']
      ? { 'en-US': originalFields.title['en-US'] }
      : undefined,
    description: originalFields.description?.['en-US']
      ? { 'en-US': originalFields.description['en-US'] }
      : undefined,
    image: originalFields.image?.['en-US']?.sys?.id
      ? {
          'en-US': {
            sys: {
              type: 'Link',
              linkType: 'Asset',
              id: originalFields.image['en-US'].sys.id,
            },
          },
        }
      : undefined,
    videoUrl: originalFields.videoUrl?.['en-US']
      ? { 'en-US': originalFields.videoUrl['en-US'] }
      : undefined,
    name: originalFields.name?.['en-US']
      ? { 'en-US': originalFields.name['en-US'] }
      : undefined,
  };

  let newAddon = await environment.createEntry('addon', {
    fields: newFields,
  });
  await updateAndPublish(newAddon);
  newAddon = await environment.getEntry(newAddon.sys.id);

  return newAddon;
};

/**
 * Clones a community by creating a new entry in the specified environment.
 *
 * @param {Object} response - The response object.
 * @param {Object} params - The parameters object containing payload and other data.
 * @param {Object} environment - The environment object.
 * @returns {Promise} - A promise that resolves when the community cloning is complete.
 */
export const cloneCommunityHandler = async (response, params, environment) => {
  console.info('Starting cloneCommunityHandler...');

  const { sourceCommunityId } = params.payload;
  let newCommunityEntry = null;
  try {
    if (!params.payload.builderTagExists) {
      const tag = params.payload.targetBuilderTag;
      const builderToAddTagEntry = await environment.getEntry(
        params.payload.builderId
      );
      await environment
        .createTag(tag, tag, 'public')
        .catch((error) => console.log('Error creating tag:', error));
      builderToAddTagEntry.fields.builderTag = {
        'en-US': tag,
      };
      await updateAndPublish(builderToAddTagEntry);
    }

    newCommunityEntry = await environment.createEntry('community', {
      fields: {
        name: {
          'en-US': params.payload.title,
        },
        builder: {
          'en-US': {
            sys: {
              type: 'Link',
              linkType: 'Entry',
              id: params.payload.builderId,
            },
          },
        },
        publishedStatus: {
          'en-US': false,
        },
      },
    });

    newCommunityEntry = await environment.getEntry(newCommunityEntry.sys.id);
    const sourceCommunity = await environment.getEntry(sourceCommunityId);
    console.log(sourceCommunity)
    newCommunityEntry.fields.banner = {
      'en-US': sourceCommunity.fields.banner['en-US'],
    };
    newCommunityEntry.fields.isBrochure = {
      'en-US': sourceCommunity.fields.isBrochure['en-US'],
    };
    newCommunityEntry.fields.pdfName = {
      'en-US': sourceCommunity.fields.pdfName['en-US'],
    };
    newCommunityEntry.fields.pdfShowCover = {
      'en-US': sourceCommunity.fields.pdfShowCover['en-US'],
    };
    newCommunityEntry.fields.pdfShowDate = {
      'en-US': sourceCommunity.fields.pdfShowDate['en-US'],
    };
    newCommunityEntry.fields.pdfShowCommunityName = {
      'en-US': sourceCommunity.fields.pdfShowCommunityName['en-US'],
    };
    newCommunityEntry.fields.pdfCoverImage = {
      'en-US': sourceCommunity.fields.pdfCoverImage['en-US'],
    };
    if (sourceCommunity.fields.pdfSelectedExpectationSheets) {
      newCommunityEntry.fields.pdfSelectedExpectationSheets = {
        'en-US': sourceCommunity.fields.pdfSelectedExpectationSheets['en-US'],
      };
    }
    if (sourceCommunity.fields.pdfGallery) {
      newCommunityEntry.fields.pdfGallery = {
        'en-US': sourceCommunity.fields.pdfGallery['en-US'],
      };
    }

    const sourceRooms = sourceCommunity?.fields?.rooms?.['en-US'] ?? [];

    for (const sourceRoom of sourceRooms) {
      const room = await cloneRoom(sourceRoom, environment);

      newCommunityEntry.fields.rooms = {
        'en-US': [
          ...(newCommunityEntry?.fields?.rooms?.['en-US'] ?? []),
          {
            sys: {
              type: 'Link',
              linkType: 'Entry',
              id: room.sys.id,
            },
          },
        ],
      };
    }
    await updateAndPublish(newCommunityEntry);
    newCommunityEntry = await environment.getEntry(newCommunityEntry.sys.id);

    const sourceCabinetLevels = sourceCommunity.fields.cabinetLevels['en-US'];
    for (const sourceCabinetLevel of sourceCabinetLevels) {
      const cabinetLevel = await cloneCabinetLevel(
        sourceCabinetLevel,
        environment
      );

      newCommunityEntry = await environment.getEntry(newCommunityEntry.sys.id);
      newCommunityEntry.fields.cabinetLevels = {
        'en-US': [
          ...(newCommunityEntry?.fields?.cabinetLevels?.['en-US'] ?? []),
          {
            sys: {
              type: 'Link',
              linkType: 'Entry',
              id: cabinetLevel.sys.id,
            },
          },
        ],
      };
      newCommunityEntry.update();
      newCommunityEntry = await environment.getEntry(newCommunityEntry.sys.id);
    }

    const sourceHardwareLevels = sourceCommunity.fields.hardwareLevels['en-US'];
    for (const sourceHardwareLevel of sourceHardwareLevels) {
      const hardwareLevel = await cloneHardwareLevel(
        sourceHardwareLevel,
        environment
      );
      newCommunityEntry = await environment.getEntry(newCommunityEntry.sys.id);
      const hardwareLevels =
        newCommunityEntry.fields.hardwareLevels?.['en-US'] ?? [];

      newCommunityEntry.fields.hardwareLevels = {
        'en-US': [
          ...hardwareLevels,
          {
            sys: {
              type: 'Link',
              linkType: 'Entry',
              id: hardwareLevel.sys.id,
            },
          },
        ],
      };

      newCommunityEntry.update();
      newCommunityEntry = await environment.getEntry(newCommunityEntry.sys.id);
    }

    newCommunityEntry = await environment.getEntry(newCommunityEntry.sys.id);
    await updateAndPublish(newCommunityEntry);
    newCommunityEntry = await environment.getEntry(newCommunityEntry.sys.id);
    const sourceRecommendations =
      sourceCommunity.fields.recommendations['en-US'];
    for (const sourceRecommendation of sourceRecommendations) {
      const newRecommendation = await cloneRecommendation(
        sourceRecommendation,
        environment
      );
      newCommunityEntry = await environment.getEntry(newCommunityEntry.sys.id);

      const recommendations =
        newCommunityEntry.fields.recommendations?.['en-US'] ?? [];
      newCommunityEntry.fields.recommendations = {
        'en-US': [
          ...recommendations,
          {
            sys: {
              type: 'Link',
              linkType: 'Entry',
              id: newRecommendation.sys.id,
            },
          },
        ],
      };

      await updateAndPublish(newCommunityEntry);
    }

    newCommunityEntry = await environment.getEntry(newCommunityEntry.sys.id);
    const sourceAddons = sourceCommunity.fields.addons?.['en-US'] ?? [];
    for (const sourceAddon of sourceAddons) {
      const addOnEntry = await environment.getEntry(sourceAddon.sys.id);
      const fields = addOnEntry.fields ?? {};
      if (
        !fields.title ||
        !fields.description ||
        !fields.image ||
        !fields.videoUrl
      ) {
        continue;
      } else {
        const newAddOn = await cloneAddon(sourceAddon, environment);

        newCommunityEntry = await environment.getEntry(
          newCommunityEntry.sys.id
        );
        const existingAddons = newCommunityEntry.fields.addons?.['en-US'] ?? [];
        newCommunityEntry.fields.addons = {
          'en-US': [
            ...existingAddons,
            {
              sys: {
                type: 'Link',
                linkType: 'Entry',
                id: newAddOn.sys.id,
              },
            },
          ],
        };
        await updateAndPublish(newCommunityEntry);
      }
    }
    let user = await environment.getEntry(params.userId);
    user.fields.communities = {
      'en-US': [
        ...(user.fields?.communities?.['en-US'] ?? []),
        {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: newCommunityEntry.sys.id,
          },
        },
      ],
    };

    const recentBuilders = user.fields.recentBuilders
      ? user.fields.recentBuilders['en-US']
      : [];

    const currentBuilderIndex = recentBuilders.findIndex(
      (builder) => builder.sys.id === params.payload.builderId
    );

    if (currentBuilderIndex === -1) {
      recentBuilders.unshift({
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: params.payload.builderId,
        },
      });
      if (recentBuilders.length > 5) {
        recentBuilders.pop();
      }
    } else {
      const currentBuilder = recentBuilders.splice(currentBuilderIndex, 1)[0];
      recentBuilders.unshift(currentBuilder);
    }

    user.fields.recentBuilders = {
      'en-US': recentBuilders,
    };
    await updateAndPublish(user);

    response = await environment.getEntry(newCommunityEntry.sys.id);
  } catch (error) {
    console.error('Error in cloneCommunityHandler:', error);
  }
  console.info('Finished cloneCommunityHandler.');
  return {
    response,
  };
};
