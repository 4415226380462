import { useState, useRef } from 'react';
import { useQuery, gql } from '@apollo/client';

export function useCommunityPdfQuery(id, onUpdate) {
  const [formattedData, setFormattedData] = useState({});
  const lastLoadSkip = useRef(null);
  const retries = useRef(0);
  const maxRetries = useRef(3);

  const formatData = (data) => {
    const { community } = data;
    const coverImage = community.pdfCoverImage ? {
      url: community.pdfCoverImage.url,
      id: community.pdfCoverImage.sys.id,
    } : null;
    const galleryArr = community.pdfGalleryCollection.items?.map((image) => {
      return {
        image: {
          url: image.url,
          id: image.sys.id
        }
      }
    })

    return {
      pdfName: community.pdfName || '',
      pdfShowCover: community.pdfShowCover ?? true,
      pdfShowDate: community.pdfShowDate ?? true,
      pdfShowCommunityName: community.pdfShowCommunityName ?? true,
      pdfCoverImage: coverImage,
      pdfSelectedExpectationSheets:
        community.pdfSelectedExpectationSheets ?? [],
      pdfGallery: galleryArr ?? [
        {
          image: {
            id: null,
            url: null,
          },
        },
      ],
    };
  };

  const GET_SINGLE_COMMUNITY_PDF = gql`
    query GetSingleCommunityPdf($id: String!) {
      community(id: $id) {
        pdfName
        pdfShowCover
        pdfShowDate
        pdfShowCommunityName
        pdfCoverImage {
          fileName
          url
          sys {
            id
          }
        }
        pdfSelectedExpectationSheets
        pdfGalleryCollection {
          items {
            fileName
            url
            sys {
              id
            }
          }
        }
      }
    }
  `;

  const { loading, error, data, fetchMore } = useQuery(
    GET_SINGLE_COMMUNITY_PDF,
    {
      variables: { id, skip: 0 },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onCompleted,
    }
  );

  function onCompleted(rawData) {
    const formattedData = formatData(rawData);
    setFormattedData(formattedData);
    if (onUpdate) {
      onUpdate(formattedData, rawData);
    }
    requestAdditionalPages(rawData);
  }

  function requestAdditionalPages(rawData) {
    const loaded = rawData?.community?.roomsCollection?.items?.length;
    const total = rawData?.community?.roomsCollection?.total;

    const isRetry = lastLoadSkip.current === loaded;
    if (!isRetry) {
      retries.current = 0;
    }

    const hitRetryLimit = retries.current >= maxRetries.current;
    if (hitRetryLimit || loaded >= total) {
      return;
    }

    lastLoadSkip.current = loaded;
    retries.current += 1;
    fetchMore({
      variables: {
        skip: loaded,
      },
    });
  }

  return { data, formattedData, loading, error };
}
