import React, { useState } from 'react';
import FieldLabel from '../forms/FieldLabel';
import DeleteIcon from '../icons/DeleteIcon';
import ImageUploadOrSelect from '../ImageUploadOrSelect';
import { useOutletContext } from 'react-router-dom';
import { useContentful } from '../../hooks/useContentful';
import Speedbump from '../modals/Speedbump';
import ErrorMessage from '../ErrorMessage';

function PdfGeneratorGalleryCard({ currentImage, index, communityId, errors }) {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const { communityData, setCommunityData } = useOutletContext();

  const handleDeleteImage = async () => {
    setConfirmDeleteOpen(false);
    const newGalleryData = [...communityData.pdfGallery];
    newGalleryData.splice(index, 1);
    setCommunityData({ ...communityData, pdfGallery: newGalleryData });
  };

  const handleSelectImage = (image) => {
    const newGalleryData = [...communityData.pdfGallery];
    newGalleryData[index].image = image;
    setCommunityData({ ...communityData, pdfGallery: newGalleryData });
  };

  return (
    <>
      <Speedbump
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={handleDeleteImage}
        onClose={() => setConfirmDeleteOpen(false)}
        confirmTitle="Delete Image"
        confirmBody="Are you sure you want to delete this image? This action cannot be undone."
        confirmBtnText="Delete"
      />
      {/*  TODO: Add <LoaderModal> for deleteImageState.loading once setup */}
      <div className="w-full">
        <FieldLabel>
          Select from gallery or upload{' '}
          <span className="text-xs text-aw-gray-300">
            (Landscape images recommended, but will be sized to fit half a page)
          </span>
        </FieldLabel>
        <ImageUploadOrSelect
          setImage={handleSelectImage}
          imageType={'Gallery Image'}
          currentImage={currentImage.image}
          targetEntryId={currentImage.id}
          imageTagToQuery="gallery"
          tagsToAddToUploads={['gallery']}
        />
        <ErrorMessage show={errors?.image !== null}>
          {errors?.image}
        </ErrorMessage>
      </div>
      <div
        className="flex w-min cursor-pointer rounded-lg border py-2 px-4 text-aw-gray-700 hover:fill-red-400 hover:text-red-400 hover:border-red-400"
        onClick={() => setConfirmDeleteOpen(true)}
      >
        <DeleteIcon className="mr-3 fill-current" />
        <span className="font-aw-sans">Delete</span>
      </div>
    </>
  );
}

export default PdfGeneratorGalleryCard;
