import {
  FlagIcon,
  CheckCircleIcon,
  XMarkIcon,
  PhotoIcon,
  CheckBadgeIcon,
  EllipsisVerticalIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DELETE_COMMUNITY } from '../../constants/contentfulActions';
import {
  NETLIFY_DELETE,
  NETLIFY_PUBLISH,
  NETLIFY_UNPUBLISH,
} from '../../constants/netlifyActions';
import { GET_PUBLISHED_URL } from '../../graphql/queries/communities';
import { useContentful } from '../../hooks/useContentful';
import useNetlifyActions from '../../hooks/useNetlifyActions';
import ButtonPrimary from '../buttons/ButtonPrimary';
import DeleteCommunityButton from '../buttons/DeleteCommunityButton';
import EditIcon from '../icons/EditIcon';
import PublishStatusModal from '../modals/PublishStatusModal';
import Speedbump from '../modals/Speedbump';
import { useQuery } from '@apollo/client';
import ButtonSecondary from '../buttons/ButtonSecondary';
import MenuItemButton from '../buttons/MenuItemButton';
import UnpublishIcon from '../icons/UnpublishIcon';
import PublishIcon from '../icons/PubllishIcon';
import netlifyIdentity from 'netlify-identity-widget';

function CommunityCard({
  communityData,
  refetch: refetchAllCommunities,
  userId,
}) {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [newPublishedUrl, setNewPublishedUrl] = useState('');
  const [publishComplete, setPublishComplete] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const [showUnpublishSuccess, setShowUnpublishSuccess] = useState(false);
  const [dispatchDeleteCommunity, deleteCommunityState] = useContentful();
  const [confirmPublishOptionsOpen, setConfirmPublishOptionsOpen] =
    useState(false);
  const { refetch: refetchUrl } = useQuery(GET_PUBLISHED_URL, {
    variables: { id: communityData.id },
  });

  const navigate = useNavigate();
  const {
    url,
    name,
    banner,
    builder,
    publishedStatus: isPublished,
    id,
    hasBeenPublished,
  } = communityData;
  const formattedUrl = url ? url.replace('https://', '') : '';

  const handleDeleteCommunity = async () => {
    await netlifyIdentity.refresh();
    const data = {
      type: NETLIFY_DELETE,
      communityId: communityData.id,
    };
    // first delete the link to this community from the user
    await dispatchDeleteCommunity({
      type: DELETE_COMMUNITY,
      payload: {
        deleteCommunityId: communityData.id,
        deleteCommunityUserId: userId,
        deleteCommunityBuilderId: communityData.builder.id,
      },
    });
    await netlifyDelete(data);
  };

  const retryRefetchPublish = (resolve, retryCount = 0, maxRetries = 10) => {
    refetchUrl().then((updatedData) => {
      const publishedUrl = updatedData.data.community.publishedUrl;

      if (publishedUrl || retryCount >= maxRetries) {
        resolve(updatedData);
        refetchAllCommunities();
      } else {
        setTimeout(() => {
          retryRefetchPublish(resolve, retryCount + 1);
        }, 2000);
      }
    });
  };
  const onPublishCompleted = () => {
    new Promise((resolve) => {
      retryRefetchPublish(resolve);
    }).then((updatedData) => {
      const url = updatedData.data.community.publishedUrl;
      if (url) {
        setNewPublishedUrl(url);
        setPublishComplete(true);
      }
    });
  };

  const onUnpublishCompleted = () => {
    refetchAllCommunities();
  };

  const { deploy: netlifyDelete } = useNetlifyActions(refetchAllCommunities);
  const { deploy: netlifyUnpublish, deployStatus: netlifyUnpublishStatus } =
    useNetlifyActions(onUnpublishCompleted);
  const { deploy: netlifyPublish, deployStatus: netlifyPublishStatus } =
    useNetlifyActions(onPublishCompleted);

  const handlePublishMicrosite = async () => {
    setShowUnpublishSuccess(false);
    setConfirmPublishOptionsOpen(false);
    await netlifyIdentity.refresh();
    const data = {
      type: NETLIFY_PUBLISH,
      communityId: communityData.id,
    };
    await netlifyPublish(data);
  };

  const handleUnpublishMicrosite = async () => {
    setConfirmPublishOptionsOpen(false);
    await netlifyIdentity.refresh();
    const data = {
      type: NETLIFY_UNPUBLISH,
      communityId: communityData.id,
    };
    await netlifyUnpublish(data);
    setShowUnpublishSuccess(true);
  };

  useEffect(() => {
    if (netlifyPublishStatus.loading) {
      setShowPublishModal(true);
    }
  }, [netlifyPublishStatus.loading]);

  useEffect(() => {
    if (netlifyUnpublishStatus.loading) {
      setShowUnpublishModal(true);
    }
    if (netlifyUnpublishStatus.success && !netlifyUnpublishStatus.loading) {
      setShowUnpublishModal(false);
    }
  }, [netlifyUnpublishStatus.loading, netlifyUnpublishStatus.success]);

  return (
    <>
      <PublishStatusModal
        show={showPublishModal}
        setShow={setShowPublishModal}
        error={netlifyPublishStatus.error}
        loadType="publish"
        url={newPublishedUrl}
        communityName={name}
        publishComplete={publishComplete}
      />
      <PublishStatusModal
        show={showUnpublishModal}
        setShow={setShowUnpublishModal}
        error={netlifyUnpublishStatus.error}
        loadType="unpublish"
      />

      <div className="mb-8 flex flex-col rounded-lg bg-white p-0 shadow-md lg:flex-row lg:p-6 relative">
        {showUnpublishSuccess && (
          <div className="rounded-md bg-green-50 p-4 absolute right-10 z-10">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-green-800">Success!</p>
                <p className="text-sm text-green-700">
                  This microsite has been unpublished.
                </p>
              </div>
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                    onClick={() => setShowUnpublishSuccess(false)}
                  >
                    <span className="sr-only">Dismiss</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="m-0 lg:basis-1/3">
          {banner.src ? (
            <img
              src={banner.src}
              alt={name}
              className=" max-h-[200px] lg:min-h-full min-w-full rounded-t-lg md:rounded-b-none object-cover  lg:rounded-b-lg lg:rounded-lg"
            />
          ) : (
            <div className="min-h-[200px] lg:min-h-full rounded-t-lg md:rounded-b-none  h-full lg:rounded-b-lg  bg-aw-gray-200 lg:rounded-lg flex justify-center">
              <PhotoIcon className="w-24 text-aw-gray-300" />
            </div>
          )}
        </div>
        <div className="p-6 lg:basis-2/3 lg:py-6 lg:pr-4 relative">
          <div className="text-sm flex absolute top-2 lg:top-0 right-0 mr-4 align-center text-aw-gray-700">
            {!isPublished ? (
              <>
                {hasBeenPublished ? (
                  <p className="font-aw-sans font-bold">Unpublished</p>
                ) : (
                  <p className="font-aw-sans">
                    <span className="font-bold">Incomplete draft:</span>{' '}
                    Continue editing to complete
                  </p>
                )}

                <span className="inline-block ml-2">
                  <FlagIcon width={20} className="w-5 " />{' '}
                </span>
              </>
            ) : (
              <>
                <p className=" text-aw-blue-400 font-aw-sans font-bold">
                  Published
                </p>
                <span className="inline-block">
                  <CheckBadgeIcon className="w-6 ml-2 -translate-y-1 text-aw-blue-400" />
                </span>
              </>
            )}
          </div>
          <h2 className="mb-4 font-aw-sans text-3xl text-aw-blue-900">
            {name || 'Untitled Community'}
          </h2>
          <p className="mb-4 font-aw-sans text-aw-blue-900">{builder.name}</p>
          <a
            href={url}
            className="mb-4 font-aw-sans text-gray-500"
            target="_blank"
            rel="noreferrer"
          >
            {formattedUrl}
          </a>
          <hr className="my-6 border-t-2" />
          <div className="flex gap-8 text-aw-gray-300">
            <ButtonPrimary
              onClick={() =>
                navigate(`generator/${id}/overview`, {
                  state: { id: communityData.id },
                })
              }
            >
              Edit <EditIcon className="ml-3  fill-white" />
            </ButtonPrimary>
            {isPublished ? (
              <ButtonSecondary
                onClick={() => setConfirmPublishOptionsOpen(true)}
              >
                Unpublish
                <UnpublishIcon className="w-5 ml-3" />
              </ButtonSecondary>
            ) : hasBeenPublished ? (
              <ButtonPrimary onClick={() => setConfirmPublishOptionsOpen(true)}>
                Publish
                <PublishIcon className="w-5 mb-1 ml-3" />
              </ButtonPrimary>
            ) : null}
            <ButtonPrimary
              onClick={() => navigate(`pdf/${id}/customize`, {
                state: {}
              })}
            >
              Create Brochure
              <DocumentIcon className="ml-3 w-5" />
            </ButtonPrimary>
            <Menu as="div" className="ml-auto relative self-center">
              <Menu.Button className="p-1">
                <span className="sr-only">More Options</span>
                <EllipsisVerticalIcon className="w-8 text-aw-gray-700" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-10">
                  <div className="flex flex-col gap-1 p-1">
                    <Menu.Item>
                      {({ active }) => (
                        <MenuItemButton
                          onClick={() =>
                            navigate(`clone/${id}`, {
                              state: {
                                builder,
                                communityData,
                              },
                            })
                          }
                          active={active}
                        >
                          Clone Community
                        </MenuItemButton>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <DeleteCommunityButton
                          open={confirmDeleteOpen}
                          active={active}
                          setOpen={setConfirmDeleteOpen}
                          communityData={communityData}
                          refetch={refetchAllCommunities}
                          deleteCommunityState={deleteCommunityState}
                          handleDeleteCommunity={handleDeleteCommunity}
                        >
                          Delete Community
                        </DeleteCommunityButton>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <Speedbump
        open={confirmPublishOptionsOpen}
        setOpen={setConfirmPublishOptionsOpen}
        onConfirm={() => {
          !isPublished ? handlePublishMicrosite() : handleUnpublishMicrosite();
        }}
        onClose={() => {
          setConfirmPublishOptionsOpen(false);
        }}
        confirmTitle={`${
          !isPublished ? 'Publish' : 'Unpublish'
        } ${name} microsite`}
        confirmBody={
          !isPublished
            ? 'Are you sure you want to publish this microsite?'
            : 'Are you sure you want to unpublish this microsite?'
        }
        confirmBtnText={`${!isPublished ? 'Publish' : 'Unpublish'}`}
        warning={false}
      />
    </>
  );
}

export default CommunityCard;
