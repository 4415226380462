import netlifyIdentity from 'netlify-identity-widget';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useLocation,
  useOutletContext,
} from 'react-router-dom';
import App from './App';
import {
  GeneratorCabinets,
  GeneratorHardware,
  GeneratorOverview,
  GeneratorPublish,
  GeneratorRecommendations,
  GeneratorAddons,
} from './containers/generator';
import {
  BrochureOverview,
} from './containers/brochure';
import {
  PdfGeneratorCustomize,
  PdfGeneratorPreview,
} from './containers/pdf';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Communities from './screens/Communities';
import CreateBrochure from './screens/CreateBrochure';
import CreateCommunity from './screens/CreateCommunity';
import Generator from './screens/Generator';
import PdfGenerator from './screens/PdfGenerator';
import BrochureGenerator from './screens/BrochureGenerator';
import Login from './screens/Login';
import ErrorBoundary from './components/ErrorBoundary';
import Error from './components/Error';
import CloneCommunity from './screens/CloneCommunity';
netlifyIdentity.init();

function GuardedRoute() {
  const outletContext = useOutletContext();
  const location = useLocation();
  if (!outletContext.currentUser) {
    return <Navigate to={`/login${location.hash}`} />;
  }
  return <Outlet context={outletContext} />;
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route path="/login" element={<Login />} />
      <Route element={<GuardedRoute />}>
        <Route path="/" element={<Communities />} />
        <Route path="/new" element={<CreateCommunity />} />
        <Route path="/brochure" element={<CreateBrochure />} />
        <Route path="/brochure/:id" element={<BrochureGenerator />}>
          <Route path="overview" element={<BrochureOverview />} />
          <Route path="cabinets" element={<GeneratorCabinets />} />
          <Route path="hardware" element={<GeneratorHardware />} />
          <Route path="customize" element={<PdfGeneratorCustomize />} />
          <Route path="preview" element={<PdfGeneratorPreview />} />
        </Route>
        <Route path="/clone/:id" element={<CloneCommunity />} />
        <Route path="/generator/:id" element={<Generator />}>
          <Route path="overview" element={<GeneratorOverview />} />
          <Route path="cabinets" element={<GeneratorCabinets />} />
          <Route path="hardware" element={<GeneratorHardware />} />
          <Route
            path="recommendations"
            element={<GeneratorRecommendations />}
          />
          <Route path="addons" element={<GeneratorAddons />} />
          <Route path="publish" element={<GeneratorPublish />} />
        </Route>
        <Route path="/pdf/:id" element={<PdfGenerator />}>
          <Route path="customize" element={<PdfGeneratorCustomize />} />
          <Route path="preview" element={<PdfGeneratorPreview />} />
        </Route>
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary onError={<Error />}>
      <RouterProvider router={router} />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
