import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonPrimary from '../buttons/ButtonPrimary';
import AddNewIcon from '../icons/AddNewIcon';
import { useNavigate } from 'react-router-dom';

function CreateProjectModal({ open, setOpen }) {
  const navigate = useNavigate();
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60]"
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0 font-aw-sans">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <Dialog.Title
                  as="h2"
                  className="text-2xl font-bold leading-6 text-gray-900 text-center mb-6"
                >
                  Project Type
                </Dialog.Title>
                <div className="flex flex-col sm:flex-row gap-4">
                  <div className="w-full p-4 bg-gray-100 rounded flex flex-col">
                    <p className="font-bold text-lg leading-tight">Brochure</p>
                    <ul className="list-disc list-outside pl-4 mt-4 mb-6 space-y-2">
                      <li>
                        Create a downloadable PDF to share product selections.
                      </li>
                    </ul>
                    <ButtonPrimary className="mt-auto self-start" onClick={() => navigate('/brochure')}>
                      Add New Brochure
                      <AddNewIcon className="ml-4" />
                    </ButtonPrimary>
                  </div>
                  <div className="w-full p-4 bg-gray-100 rounded flex flex-col">
                    <p className="font-bold text-lg leading-tight">
                      Community Microsite & Brochure
                    </p>
                    <ul className="list-disc list-outside pl-4 mt-4 mb-6 space-y-2">
                      <li>Generate a custom URL for homebuyers.</li>
                      <li>
                        Homebuyers can browse products and create a PDF of their
                        selections to collaborate with their builder.
                      </li>
                      <li>
                        Option to create a PDF builder brochure with all
                        products.
                      </li>
                    </ul>
                    <ButtonPrimary
                      className="mt-auto self-start"
                      onClick={() => navigate('/new')}
                    >
                      Add New Microsite
                      <AddNewIcon className="ml-4" />
                    </ButtonPrimary>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default CreateProjectModal;
