import { useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { FieldLabel, TextInput } from '../components/forms';
import {
  GeneratorBuilderCard,
  GeneratorSelectBuilder,
} from '../components/generator';
import { SizeConstrained } from '../components/layout/Layout';
import { GET_COMMUNITIES_BY_BUILDER } from '../graphql/queries/communities';
import { GET_BUILDERS } from '../graphql/queries/builders';
import { GET_CURRENT_USER_ID } from '../graphql/queries/users';
import { CREATE_COMMUNITY } from '../constants/contentfulActions';
import { H1 } from '../components/typography';
import ButtonTertiary from '../components/buttons/ButtonTertiary';
import Speedbump from '../components/modals/Speedbump';
import {
  checkCommunityNameUnique,
  extractBuildersData,
  extractCommunityNamesFromBuilders,
} from '../graphql/helpers';
import LoadingSpinner from '../components/LoadingSpinner';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { useContentful } from '../hooks/useContentful';
import slugify from 'slugify';
import ErrorMessage from '../components/ErrorMessage';
import LoaderModal from '../components/modals/LoaderModal';

function CreateBrochure() {
  const [communityName, setCommunityName] = useState('');
  const [userId, setUserId] = useState(null);
  const [builder, setBuilder] = useState(null);
  const [isCommunityNameValid, setIsCommunityNameValid] = useState(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [currentExistingCommunityNames, setCurrentExistingCommunityNames] =
    useState([]);
  const [dispatchCreateCommunity, createCommunityState] = useContentful();
  const [allBuildersData, setAllBuildersData] = useState([]);
  const { currentUser } = useOutletContext();
  const navigate = useNavigate();

  const { data: builderCommunitiesData } = useQuery(
    GET_COMMUNITIES_BY_BUILDER,
    {
      variables: { builderId: builder?.id },
      skip: builder === null,
    }
  );

  const handleRemoveBuilder = () => {
    setBuilder(null);
    setCommunityName('');
  };

  const { loading: buildersLoading, data: buildersData } = useQuery(
    GET_BUILDERS,
    {
      variables: { email: currentUser.email },
      skip: currentUser === null,
    }
  );

  const { loading: userIdLoading } = useQuery(GET_CURRENT_USER_ID, {
    variables: { email: currentUser.email },
    errorPolicy: 'ignore',
    onCompleted: (data) => {
      setUserId(data.userCollection.items[0].sys.id);
    },
  });
  useEffect(() => {
    if (builderCommunitiesData) {
      setCurrentExistingCommunityNames(
        extractCommunityNamesFromBuilders(builderCommunitiesData)
      );
    }
  }, [builderCommunitiesData]);

  useEffect(() => {
    setIsCommunityNameValid(
      checkCommunityNameUnique(communityName, currentExistingCommunityNames)
    );
  }, [communityName, currentExistingCommunityNames]);

  useEffect(() => {
    if (buildersData) {
      setAllBuildersData(extractBuildersData(buildersData));
    }
  }, [buildersData]);

  useEffect(() => {
    if (createCommunityState.success) {
      navigate(`/brochure/${createCommunityState.data.sys.id}/overview`);
    }
  }, [createCommunityState.success, navigate, createCommunityState.data]);

  const handleCreateNewCommunity = () => {
    setConfirmModalOpen(false);
    dispatchCreateCommunity({
      type: CREATE_COMMUNITY,
      payload: {
        title: communityName,
        isBrochure: true,
        builderId: builder.id,
        builderTagExists: !!builder?.builderTag,
        targetBuilderTag: slugify(builder.name, { lower: true, strict: true }),
      },
      userId,
    });
  };

  if (buildersLoading || userIdLoading) return <LoadingSpinner />;

  return (
    <>
      <LoaderModal show={createCommunityState.loading} />
      <SizeConstrained>
        <H1 className="my-12">Create New Brochure</H1>
        <div className="flex w-full gap-12">
          <div className={`${builder === null ? 'w-1/4' : 'w-full'}`}>
            <FieldLabel>Select builder:</FieldLabel>

            {builder !== null ? (
              <GeneratorBuilderCard
                builder={builder}
                isSelected={true}
                isNewCommunity
                handleRemoveBuilder={handleRemoveBuilder}
                setBuilder={setBuilder}
              />
            ) : (
              <GeneratorSelectBuilder
                allBuildersData={allBuildersData || []}
                setBuilder={setBuilder}
              />
            )}
            <p className="my-4 font-aw-sans text-gray-600 text-xs italic">
              Note: You will not be able to change builder once this community
              has been created
            </p>
          </div>
          {builder !== null && (
            <div className="w-full relative">
              <TextInput
                label="Brochure Name"
                value={communityName}
                onChange={(e) => setCommunityName(e.target.value)}
              />

              <ErrorMessage show={!isCommunityNameValid}>
                Brochure name already exists for this builder. Please choose a
                different one.
              </ErrorMessage>
            </div>
          )}
        </div>
        <div className="flex justify-end mt-8">
          <ButtonTertiary
            onClick={() => setConfirmModalOpen(true)}
            disabled={!isCommunityNameValid || !builder || communityName === ''}
          >
            Customize Brochure <ArrowRightIcon className="ml-4 h-5 w-5" />
          </ButtonTertiary>
        </div>
      </SizeConstrained>
      <Speedbump
        open={confirmModalOpen}
        setOpen={setConfirmModalOpen}
        onConfirm={handleCreateNewCommunity}
        confirmTitle={`Is ${builder?.name} the correct builder?`}
        confirmBody="Once you proceed, you will not be able to change the builder for this brochure."
        confirmBtnText={'Continue'}
        warning={false}
      />
    </>
  );
}

export default CreateBrochure;
