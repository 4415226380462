import React from 'react';

function MenuItemButton({ children, onClick, className, active = false }) {
  return (
    <button
      className={`${
        active ? 'bg-aw-gray-100' : ''
      } disabled:text-gray-300 hover:bg-aw-gray-100 py-2 px-3 rounded-md font-semibold text-gray-900 select-none ${className}`}
      onClick={onClick}
      role="menuitem"
    >
      {children}
    </button>
  );
}

export default MenuItemButton;
