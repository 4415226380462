import { gql } from '@apollo/client';

export const GET_COMMUNITIES_BY_USER = gql`
  query GetCommunities($email: String!) {
    userCollection(where: { email: $email }, limit: 1) {
      items {
        sys {
          id
        }
        communitiesCollection(limit: 100) {
          items {
            name
            isBrochure
            publishedStatus
            publishedUrl
            previewUrl
            sys {
              id
            }
            builder {
              name
              url
              logo {
                url
              }
              sys {
                id
              }
            }
            banner {
              url
              title
              fileName
              sys {
                id
              }
            }
            pdfCoverImage {
              url
              title
              fileName
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PUBLISHED_URL = gql`
  query GetSingleCommunity($id: String!) {
    community(id: $id) {
      publishedUrl
    }
  }
`;

export const GET_SINGLE_COMMUNITY = gql`
  query GetSingleCommunity($id: String!) {
    community(id: $id) {
      sys {
        id
        publishedAt
      }
      name
      recommendationsCollection {
        items {
          sys {
            id
          }
          product {
            sys {
              id
            }
          }
          image {
            sys {
              id
            }
            url
          }
        }
      }
      publishedUrl
      previewUrl
      publishedStatus
      isBrochure
      banner {
        fileName
        url
        sys {
          id
        }
      }
      pdfCoverImage {
        fileName
        url
        sys {
          id
        }
      }
      builder {
        name
        url
        builderTag
        logo {
          url
        }
        sys {
          id
        }
      }
      addonsCollection {
        items {
          sys {
            id
          }
          title
          videoUrl
          description {
            json
          }
          image {
            url
            sys {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_COMMUNITIES_BY_BUILDER = gql`
  query GetCommunitiesByBuilder($builderId: String!) {
    communityCollection(where: { builder: { sys: { id: $builderId } } }) {
      items {
        name
        publishedStatus
        publishedUrl
        previewUrl
        sys {
          id
        }
        builder {
          name
          url
          logo {
            url
          }
          sys {
            id
          }
        }
        banner {
          url
          title
          fileName
          sys {
            id
          }
        }
      }
    }
  }
`;
