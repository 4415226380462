import { gql } from '@apollo/client';

export const GET_EXPECTATION_SHEETS = gql`
  query GetExpectationSheet {
    expectationSheetCollection {
      items {
        name
        pdf {
          url
        }
        sys {
          id
        }
      }
    }
  }
`;
