import React, { useEffect, useState } from 'react';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import FieldLabel from '../../components/forms/FieldLabel';
import TextInput from '../../components/forms/TextInput';
import {
  GeneratorHeader,
  GeneratorSubheader,
  GeneratorBuilderCard,
  GeneratorNavigation,
  GeneratorRoomBuilder,
} from '../../components/generator';
import ImageUploadOrSelect from '../../components/ImageUploadOrSelect';
import { SizeConstrained } from '../../components/layout/Layout';
import { GET_COMMUNITIES_BY_BUILDER } from '../../graphql/queries/communities';
import { useQuery } from '@apollo/client';
import {
  ALLOWED_ROOMS_LIMIT,
  checkCommunityNameUnique,
  extractCommunityNamesFromBuilders,
} from '../../graphql/helpers';
import ErrorMessage from '../../components/ErrorMessage';
import Speedbump from '../../components/modals/Speedbump';

function BrochureOverview() {
  const { id } = useParams();
  const {
    communityData,
    setCommunityData,
    initialCommunityName,
    builderImageTag,
    isCommunityLoaded,
  } = useOutletContext();
  const { state: locationState } = useLocation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [roomLimitAlert, setRoomLimitAlert] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    rooms: {},
    banner: null,
    name: null,
  });
  const { loading: builderLoading, data: builderCommunitiesData } = useQuery(
    GET_COMMUNITIES_BY_BUILDER,
    {
      variables: { builderId: communityData?.builder?.id },
    }
  );

  const currentExistingCommunityNames = extractCommunityNamesFromBuilders(
    builderCommunitiesData
  );
  const isCommunityNameValid = checkCommunityNameUnique(
    communityData.name,
    currentExistingCommunityNames,
    initialCommunityName
  );

  const checkNestedErrors = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        if (checkNestedErrors(obj[key])) {
          return true;
        }
      } else if (obj[key] !== null && obj[key] !== '') {
        return true;
      }
    }
    return false;
  };

  const validateFields = () => {
    const errors = {
      rooms: {},
      banner: null,
      name: null,
    };

    if (!communityData.name) {
      errors.name = 'Please enter a brochure name';
    }

    if (!isCommunityNameValid) {
      errors.name =
        'Brochure name already exists for this builder. Please choose a different one.';
    }

    communityData.rooms.forEach((room) => {
      if (room.products.length === 0) {
        errors.rooms[room.id].products = `Please select at least one product`;
      }
    });

    setValidationErrors(errors);

    const hasErrors = checkNestedErrors(errors);

    return !hasErrors;
  };

  useEffect(() => {
    if (!builderLoading && isCommunityLoaded) {
      setCommunityData({
        ...communityData,
        builderCurrentCommunities: builderCommunitiesData,
      });

      setDataLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builderLoading, isCommunityLoaded]);

  const handleCommunityNameChange = (e) => {
    setCommunityData({ ...communityData, name: e.target.value });
  };

  const handleSetCoverImage = (image) => {
    setCommunityData({ ...communityData, pdfCoverImage: image });
  };

  return (
    <>
      {/* TODO: This speedbump and corresponding logic should be adjusted/removed when the room limit is increased. */}
      <Speedbump
        warning={true}
        open={roomLimitAlert}
        setOpen={setRoomLimitAlert}
        onConfirm={() => setRoomLimitAlert(false)}
        onClose={() => setRoomLimitAlert(false)}
        confirmBtnText="OK"
        confirmBody={`Please limit your microsite to ${ALLOWED_ROOMS_LIMIT} or fewer rooms.`}
      />
      <SizeConstrained>
        <GeneratorHeader
          title={
            locationState
              ? `Edit ${
                  communityData.name ? communityData.name : 'New Brochure'
                }`
              : `Add A New Brochure`
          }
        />
        <GeneratorSubheader title="Brochure Overview" />
        <div className="flex w-full gap-12">
          <div className="w-full relative">
            <TextInput
              label="Brochure name:"
              value={communityData.name}
              onChange={handleCommunityNameChange}
            />
            <ErrorMessage show={!isCommunityNameValid}>
              Brochure name already exists for this builder. Please choose a
              different one.
            </ErrorMessage>
            <ErrorMessage show={validationErrors.name}>
              {validationErrors.name}
            </ErrorMessage>
          </div>
          <div className="w-full">
            <FieldLabel>Builder:</FieldLabel>
            <GeneratorBuilderCard
              builder={communityData.builder}
              isSelected={true}
            />
          </div>
        </div>
        <div className="border-b py-12">
          <FieldLabel>
            Add cover image:{' '}
            <span className="ml-4 inline text-xs text-aw-gray-300">
              3080x840 recommended
            </span>
          </FieldLabel>
          {/* replace galleryData when we have gallery data */}
          <ImageUploadOrSelect
            imageType="Cover Image"
            setImage={handleSetCoverImage}
            currentImage={communityData?.pdfCoverImage ?? { id: null, url: null }}
            targetEntryId={communityData.id}
            tagsToAddToUploads={[builderImageTag, 'hero']}
            imageTagToQuery="hero"
          />
          <ErrorMessage show={validationErrors.banner !== null}>
            {validationErrors.banner}
          </ErrorMessage>
        </div>
        <div className="my-12">
          <div>
            {communityData.rooms.length ? (
              communityData.rooms.map((room, index) => (
                <GeneratorRoomBuilder
                  currentRoom={room}
                  index={index}
                  key={room.id}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
        <GeneratorNavigation
          nextText="Define Cabinet Levels"
          nextRoute={`/brochure/${id}/cabinets`}
          validateFields={validateFields}
          dataLoaded={dataLoaded}
        />
      </SizeConstrained>
    </>
  );
}

export default BrochureOverview;
