import React, { useState } from 'react';
import { useParams } from 'react-router';
import { P } from '../../components/typography';
import GeneratorHeader from '../../components/generator/GeneratorHeader';
import GeneratorNavigation from '../../components/generator/GeneratorNavigation';
import LevelSelector from '../../components/generator/level-selector/LevelSelector.jsx';
import { SizeConstrained } from '../../components/layout/Layout';
import { useOutletContext } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_SELECTED_PRODUCTS } from '../../graphql/queries/products';

// NOTE: This is used for both Community and Brochure edit flows

function GeneratorCabinets() {
  const { communityData, isCommunityLoaded } = useOutletContext();
  const { id } = useParams();
  const [validationErrors, setValidationErrors] = useState({
    unassignedProducts: null,
  });
  const { data: selectedCabinetData } = useQuery(GET_SELECTED_PRODUCTS, {
    variables: { ids: communityData.uniqueCabinetSelections },
  });
  const [selectedIds, setSelectedIds] = useState([]);

  const validateFields = () => {
    const errors = {
      unassignedProducts: null,
    };

    let uncategorizedObj;
    for (const id in communityData.cabinetLevels) {
      if (
        communityData.cabinetLevels.hasOwnProperty(id) &&
        communityData.cabinetLevels[id].title === 'Uncategorized'
      ) {
        uncategorizedObj = communityData.cabinetLevels[id];
        break;
      }
    }

    const uncategorizedIdsLength = uncategorizedObj.ids.length;

    if (uncategorizedIdsLength > 0) {
      errors.unassignedProducts = `All products must be assigned to a level.`;
    }
    setValidationErrors(errors);

    return uncategorizedIdsLength === 0;
  };

  return (
    <div>
      <SizeConstrained>
        <GeneratorHeader title="Define Cabinetry Levels (Product Tiers)" />
        <P className="mb-5">
          Starting with your base offerings on the left, create additional
          levels to represent your community’s products. Name each level and
          provide a description. Select one or more uncategorized products and
          drag each into a product level.
        </P>
      </SizeConstrained>
      <LevelSelector
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        itemData={selectedCabinetData?.productCollection?.items}
        levelDataKey="cabinetLevels"
        forCabinets={true}
        errors={validationErrors}
      />

      <SizeConstrained>
        <GeneratorNavigation
          nextText="Define Hardware Levels"
          prevRoute={
            communityData.isBrochure
              ? `/brochure/${id}/overview`
              : `/generator/${id}/overview`
          }
          nextRoute={
            communityData.isBrochure
              ? `/brochure/${id}/hardware`
              : `/generator/${id}/hardware`
          }
          validateFields={validateFields}
          dataLoaded={isCommunityLoaded}
        />
      </SizeConstrained>
    </div>
  );
}

export default GeneratorCabinets;
