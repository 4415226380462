import {
  PhotoIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DELETE_COMMUNITY } from '../../constants/contentfulActions';
import {
  NETLIFY_DELETE,
  NETLIFY_PUBLISH,
  NETLIFY_UNPUBLISH,
} from '../../constants/netlifyActions';
import { GET_PUBLISHED_URL } from '../../graphql/queries/communities';
import { useContentful } from '../../hooks/useContentful';
import useNetlifyActions from '../../hooks/useNetlifyActions';
import ButtonPrimary from '../buttons/ButtonPrimary';
import DeleteCommunityButton from '../buttons/DeleteCommunityButton';
import EditIcon from '../icons/EditIcon';
import { useQuery } from '@apollo/client';
import MenuItemButton from '../buttons/MenuItemButton';
import PreviewIcon from '../icons/PreviewIcon';
import netlifyIdentity from 'netlify-identity-widget';

// NOTE: This is a simplified variant of the CommunityCard

function BrochureCard({
  communityData,
  refetch: refetchAllCommunities,
  userId,
}) {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [dispatchDeleteCommunity, deleteCommunityState] = useContentful();
    useState(false);
  const { refetch: refetchUrl } = useQuery(GET_PUBLISHED_URL, {
    variables: { id: communityData.id },
  });

  const navigate = useNavigate();
  const {
    name,
    builder,
    id,
    banner,
    pdfCoverImage,
  } = communityData;

  const handleDeleteCommunity = async () => {
    await netlifyIdentity.refresh();
    const data = {
      type: NETLIFY_DELETE,
      communityId: communityData.id,
    };
    // first delete the link to this community from the user
    await dispatchDeleteCommunity({
      type: DELETE_COMMUNITY,
      payload: {
        deleteCommunityId: communityData.id,
        deleteCommunityUserId: userId,
        deleteCommunityBuilderId: communityData.builder.id,
      },
    });
    await netlifyDelete(data);
  };

  const { deploy: netlifyDelete } = useNetlifyActions(refetchAllCommunities);

  return (
    <>
      <div className="mb-8 flex flex-col rounded-lg bg-white p-0 shadow-md lg:flex-row lg:p-6 relative">
        <div className="m-0 lg:basis-1/3">
          {pdfCoverImage?.src ? (
            <img
              src={pdfCoverImage.src}
              alt={name}
              className=" max-h-[200px] lg:min-h-full min-w-full rounded-t-lg md:rounded-b-none object-cover  lg:rounded-b-lg lg:rounded-lg"
            />
          ) : (
            <div className="min-h-[200px] lg:min-h-full rounded-t-lg md:rounded-b-none  h-full lg:rounded-b-lg  bg-aw-gray-200 lg:rounded-lg flex justify-center">
              <PhotoIcon className="w-24 text-aw-gray-300" />
            </div>
          )}
        </div>
        <div className="p-6 lg:basis-2/3 lg:py-6 lg:pr-4 relative">
          <h2 className="mb-4 font-aw-sans text-3xl text-aw-blue-900">
            {name || 'Untitled Brochure'}
          </h2>
          <p className="mb-4 font-aw-sans text-aw-blue-900">{builder.name}</p>
          <hr className="my-6 border-t-2" />
          <div className="flex gap-8 text-aw-gray-300">
            <ButtonPrimary
              onClick={() =>
                navigate(`brochure/${id}/overview`, {
                  state: { id: communityData.id },
                })
              }
            >
              Edit <EditIcon className="ml-3  fill-white" />
            </ButtonPrimary>
            <ButtonPrimary
              onClick={() =>
                navigate(`brochure/${id}/preview`, {
                  state: { id: communityData.id },
                })
              }
            >
              Preview &amp; Download <PreviewIcon className="ml-3 fill-white w-6" />
            </ButtonPrimary>
            <Menu as="div" className="ml-auto relative self-center">
              <Menu.Button className="p-1">
                <span className="sr-only">More Options</span>
                <EllipsisVerticalIcon className="w-8 text-aw-gray-700" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-10">
                  <div className="flex flex-col gap-1 p-1">
                    <Menu.Item>
                      {({ active }) => (
                        <MenuItemButton
                          onClick={() =>
                            navigate(`clone/${id}`, {
                              state: {
                                builder,
                                communityData,
                              },
                            })
                          }
                          active={active}
                        >
                          Clone Brochure
                        </MenuItemButton>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <DeleteCommunityButton
                          open={confirmDeleteOpen}
                          active={active}
                          setOpen={setConfirmDeleteOpen}
                          communityData={communityData}
                          refetch={refetchAllCommunities}
                          deleteCommunityState={deleteCommunityState}
                          handleDeleteCommunity={handleDeleteCommunity}
                        >
                          Delete Brochure
                        </DeleteCommunityButton>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}

export default BrochureCard;
