export const createCommunityHandler = async (response, params, environment) => {
  let newCommunityEntry = null;
  let roomEntry = null;
  let hardwareLevelEntry = null;
  let hardwareLevelOneEntry = null;
  let cabinetLevelEntry = null;
  let cabinetLevelOneEntry = null;
  try {
    // Create community entry
    newCommunityEntry = await environment
      .createEntry('community', {
        fields: {
          name: {
            'en-US': params.payload.title,
          },
          isBrochure: {
            'en-US': params.payload.isBrochure,
          },
          builder: {
            'en-US': {
              sys: {
                type: 'Link',
                linkType: 'Entry',
                id: params.payload.builderId,
              },
            },
          },
          publishedStatus: {
            'en-US': false,
          },
        },
      })
      .then((entry) => entry.publish());

    // Create room entry
    roomEntry = await environment
      .createEntry('room', {})
      .then((entry) => entry.publish());

    // Create uncategorized and first hardware level entry
    hardwareLevelEntry = await environment
      .createEntry('level', {
        fields: {
          title: {
            'en-US': 'Uncategorized',
          },
        },
      })
      .then((entry) => entry.publish());

    hardwareLevelOneEntry = await environment
      .createEntry('level', {
        fields: {
          title: {
            'en-US': 'Level 1',
          },
        },
      })
      .then((entry) => entry.publish());

    // Create uncategorized and first cabinet level entry
    cabinetLevelEntry = await environment
      .createEntry('level', {
        fields: {
          title: {
            'en-US': 'Uncategorized',
          },
        },
      })
      .then((entry) => entry.publish());

    cabinetLevelOneEntry = await environment
      .createEntry('level', {
        fields: {
          title: {
            'en-US': 'Level 1',
          },
        },
      })
      .then((entry) => entry.publish());

    // Update community entry with link to room, hardwareLevels, and cabinetLevels entries
    const community = await environment.getEntry(newCommunityEntry.sys.id);
    community.fields.rooms = {
      'en-US': [
        ...(community.fields?.rooms?.['en-US'] ?? []),
        {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: roomEntry.sys.id,
          },
        },
      ],
    };
    community.fields.hardwareLevels = {
      'en-US': [
        ...(community.fields?.hardwareLevels?.['en-US'] ?? []),
        {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: hardwareLevelEntry.sys.id,
          },
        },
        {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: hardwareLevelOneEntry.sys.id,
          },
        },
      ],
    };
    community.fields.cabinetLevels = {
      'en-US': [
        ...(community.fields?.cabinetLevels?.['en-US'] ?? []),
        {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: cabinetLevelEntry.sys.id,
          },
        },
        {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: cabinetLevelOneEntry.sys.id,
          },
        },
      ],
    };
    const updatedCommunity = await community
      .update()
      .then((updatedCommunity) => updatedCommunity.publish());

    if (!params.payload.builderTagExists) {
      const tag = params.payload.targetBuilderTag;
      const builderToAddTagEntry = await environment.getEntry(
        params.payload.builderId
      );
      await environment
        .createTag(tag, tag, 'public')
        .catch((error) => console.error(error));
      builderToAddTagEntry.fields.builderTag = {
        'en-US': tag,
      };
      await builderToAddTagEntry
        .update()
        .then((updatedEntry) => updatedEntry.publish());
    }

    // Update user entry with link to community entry
    const user = await environment.getEntry(params.userId);
    user.fields.communities = {
      'en-US': [
        ...(user.fields?.communities?.['en-US'] ?? []),
        {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: updatedCommunity.sys.id,
          },
        },
      ],
    };

    const recentBuilders = user.fields.recentBuilders
      ? user.fields.recentBuilders['en-US']
      : [];

    // check if current builder is already in the recentBuilders list
    const currentBuilderIndex = recentBuilders.findIndex(
      (builder) => builder.sys.id === params.payload.builderId
    );

    if (currentBuilderIndex === -1) {
      // if the current builder is not in the list, add it to the front and remove the oldest builder if there are already 5 builders in the list
      recentBuilders.unshift({
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: params.payload.builderId,
        },
      });
      if (recentBuilders.length > 5) {
        recentBuilders.pop();
      }
    } else {
      // if the current builder is already in the list, move it to the front
      const currentBuilder = recentBuilders.splice(currentBuilderIndex, 1)[0];
      recentBuilders.unshift(currentBuilder);
    }

    user.fields.recentBuilders = {
      'en-US': recentBuilders,
    };
    await user.update().then((updatedUser) => updatedUser.publish());
    response = await environment.getEntry(newCommunityEntry.sys.id);
  } catch (error) {
    console.error(error);
  }
  return {
    response,
  };
};
