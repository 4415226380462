import React from 'react';

const PublishIcon = (props) => {
  return (
    <svg
      viewBox="0 0 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m543.75 187.5v637.5c0 31.051 25.199 56.25 56.25 56.25s56.25-25.199 56.25-56.25v-637.5c0-31.051-25.199-56.25-56.25-56.25s-56.25 25.199-56.25 56.25z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="m827.25 297.75-187.5-187.5c-21.977-21.977-57.523-21.977-79.5 0l-187.5 187.5c-21.977 21.898-21.977 57.602 0 79.5 21.898 21.977 57.602 21.977 79.5 0l147.75-147.68 147.75 147.68c21.898 21.977 57.602 21.977 79.5 0 21.977-21.898 21.977-57.602 0-79.5z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="m900 581.25h75c10.352 0 18.75 8.3984 18.75 18.75v375c0 4.9492-1.9492 9.75-5.4766 13.273-3.5234 3.5234-8.3242 5.4766-13.273 5.4766h-750c-4.9492 0-9.75-1.9492-13.273-5.4766-3.5234-3.5234-5.4766-8.3242-5.4766-13.273v-375c0-10.352 8.3984-18.75 18.75-18.75h75c31.051 0 56.25-25.199 56.25-56.25s-25.199-56.25-56.25-56.25h-75c-72.449 0-131.25 58.727-131.25 131.25v375c0 34.801 13.801 68.176 38.477 92.773 24.602 24.676 57.977 38.477 92.773 38.477h750c34.801 0 68.176-13.801 92.773-38.477 24.676-24.602 38.477-57.977 38.477-92.773v-375c0-72.523-58.801-131.25-131.25-131.25h-75c-31.051 0-56.25 25.199-56.25 56.25s25.199 56.25 56.25 56.25z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default PublishIcon;
