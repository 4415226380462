import React from 'react';

export function SizeConstrained({ children, topMost, className }) {
  return (
    <div
      className={`container mx-auto sm:px-6 lg:px-8 ${topMost ? 'pt-16' : ''} ${
        className && className
      }`}
    >
      {children}
    </div>
  );
}

function Layout({ children, suppressSpacing }) {
  return (
    <>
      <div className={`${suppressSpacing ? '' : 'pb-32 pt-[88px]'}`}>{children}</div>
    </>
  );
}

export default Layout;
