import { v4 as uuidv4 } from 'uuid';

export const COMMUNITY_DATA_TEMPLATE = {
  name: '',
  banner: {},
  rooms: [
    {
      id: uuidv4(),
      name: '',
      image: {},
      selections: [],
    },
  ],
  addOns: [],
  recommendations: [],
  recentBuilders: [],
  uniqueCabinetSelections: [],
  uniqueHardwareSelections: [],
  roomsTotal: null,
  cabinetLevelsTotal: null,
  hardwareLevelsTotal: null,
  communityDetailsLoaded: false,
  // brochure fields
  isBrochure: false,
  // pdf fields
  pdfName: '',
  pdfShowCover: true,
  pdfShowDate: true,
  pdfShowCommunityName: true,
  pdfCoverImage: null,
  pdfSelectedExpectationSheets: [],
  pdfGallery: [],
};
