import { ArrowsUpDownIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import H1 from '../../components/typography/H1';
import H2 from '../../components/typography/H2';
import Layout, { SizeConstrained } from '../../components/layout/Layout';
import ButtonPrimary from '../../components/buttons/ButtonPrimary.jsx';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import FieldLabel from '../../components/forms/FieldLabel';
import TextInput from '../../components/forms/TextInput';
import PdfGeneratorGalleryCard from '../../components/pdf/PdfGeneratorGalleryCard';
import SquarePlusIcon from '../../components/icons/SquarePlusIcon';
import DeleteIcon from '../../components/icons/DeleteIcon';
import ImageUploadOrSelect from '../../components/ImageUploadOrSelect';
import GeneratorNavigation from '../../components/generator/GeneratorNavigation';
import { useContentful } from '../../hooks/useContentful';
import { UPDATE_COMMUNITY } from '../../constants/contentfulActions';
import { ALLOWED_PDF_GALLERY_LIMIT } from '../../graphql/helpers';
import ErrorMessage from '../../components/ErrorMessage';
import Speedbump from '../../components/modals/Speedbump';
import { GeneratorHeader } from '../../components/generator';

// NOTE: This is used for both Brochure and separate Commnunity Brochure edit flow

function PdfGeneratorCustomize() {
  const { id } = useParams();
  const {
    communityData,
    setCommunityData,
    expectationSheets,
    refetchCommunityData,
    isCommunityLoaded,
  } = useOutletContext();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [imageLimitAlert, setImageLimitAlert] = useState(false);
  const [errors, setErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    name: null,
    coverImage: null,
    gallery: {},
  });
  const [sortedExpectationSheets, setSortedExpectationSheets] = useState([]);
  const navigate = useNavigate();

  const [dispatchUpdateCommunity, updateCommunityState, resetContentfulState] =
    useContentful();

  useEffect(() => {
    if (updateCommunityState.success) {
      refetchCommunityData();
      resetContentfulState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCommunityState.success]);

  // Sort the items alphabetically by name
  useEffect(() => {
    const sortedItems = [...expectationSheets].sort((a, b) => a.name.localeCompare(b.name));
    setSortedExpectationSheets(sortedItems);
  }, [expectationSheets]);

  const handleUpdateCommunity = async (updateCommunityData) => {
    await dispatchUpdateCommunity({
      type: UPDATE_COMMUNITY,
      payload: {
        communityData: updateCommunityData,
      },
    });
    setCommunityData(updateCommunityData);
  };

  const checkNestedErrors = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        if (checkNestedErrors(obj[key])) {
          return true;
        }
      } else if (obj[key] !== null && obj[key] !== '') {
        return true;
      }
    }
    return false;
  };

  const validateFields = () => {
    let errors = {
      name: null,
      coverImage: null,
      gallery: {},
    };

    if (communityData.pdfShowCover && !communityData.pdfName && !communityData.isBrochure) {
      errors.name = 'Please enter a title.';
    }

    if (communityData.pdfShowCover && communityData.pdfCoverImage == null) {
      errors.coverImage = 'Please upload or select a cover image.';
    }

    communityData.pdfGallery.forEach((image, index) => {
      if (!errors.gallery[index]) {
        errors.gallery[index] = {};
      }
      if (!image.image.url) {
        errors.gallery[index].image = `Please upload or select an image`;
      }
    });

    setValidationErrors(errors);

    const hasErrors = checkNestedErrors(errors);
    setErrors(hasErrors);
    return !hasErrors;
  };

  const handleCommunityNameChange = (e) => {
    setCommunityData({ ...communityData, name: e.target.value });
  };

  const handlePdfNameChange = (e) => {
    setCommunityData({ ...communityData, pdfName: e.target.value });
  };

  const toggleCover = () => {
    setCommunityData((prev) => ({
      ...prev,
      pdfShowCover: !prev.pdfShowCover,
    }));
  };

  const toggleDate = () => {
    setCommunityData((prev) => ({
      ...prev,
      pdfShowDate: !prev.pdfShowDate,
    }));
  };

  const toggleCommunityName = () => {
    setCommunityData((prev) => ({
      ...prev,
      pdfShowCommunityName: !prev.pdfShowCommunityName,
    }));
  };

  const handleSetCoverImage = (image) => {
    setCommunityData({ ...communityData, pdfCoverImage: image });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newGalleryData = [...communityData.pdfGallery];
    const [reorderedItem] = newGalleryData.splice(result.source.index, 1);
    newGalleryData.splice(result.destination.index, 0, reorderedItem);
    setCommunityData({ ...communityData, pdfGallery: newGalleryData });
  };

  const handleAddNewImage = async () => {
    if (communityData.pdfGallery.length < ALLOWED_PDF_GALLERY_LIMIT) {
      const blank = {
        image: {
          url: null,
        },
      };
      const newGalleryData = [...communityData.pdfGallery, blank];
      setCommunityData({ ...communityData, pdfGallery: newGalleryData });
    } else {
      setImageLimitAlert(true);
    }
  };

  const handleSetExpectationSheet = (e) => {
    if (e.target.checked) {
      setCommunityData((prevState) => ({
        ...prevState,
        pdfSelectedExpectationSheets: [
          ...prevState.pdfSelectedExpectationSheets,
          e.target.value,
        ],
      }));
    } else {
      setCommunityData((prevState) => ({
        ...prevState,
        pdfSelectedExpectationSheets:
          prevState.pdfSelectedExpectationSheets.filter((id) => {
            return id !== e.target.value;
          }),
      }));
    }
  };

  const handlePreviewAction = () => {
    handleUpdateCommunity(communityData);
    navigate(`/pdf/${id}/preview`, {
      state: { id: communityData.id },
    });
  };

  const handleDeleteCoverImage = async () => {
    setConfirmDeleteOpen(false);
    setCommunityData({ ...communityData, pdfCoverImage: null });
  };

  // Check if the sheet is already attached
  function checkInitialExpectationSheets(id) {
    return communityData.pdfSelectedExpectationSheets.includes(id);
  }

  return (
    <Layout suppressSpacing={communityData.isBrochure}>
      <Speedbump
        warning={true}
        open={imageLimitAlert}
        setOpen={setImageLimitAlert}
        onConfirm={() => setImageLimitAlert(false)}
        onClose={() => setImageLimitAlert(false)}
        confirmBtnText="OK"
        confirmBody={`Please limit your gallery to ${ALLOWED_PDF_GALLERY_LIMIT} or fewer images.`}
      />
      <Speedbump
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={handleDeleteCoverImage}
        onClose={() => setConfirmDeleteOpen(false)}
        confirmTitle="Delete Image"
        confirmBody="Are you sure you want to delete this image? This action cannot be undone."
        confirmBtnText="Delete"
      />
      <SizeConstrained topMost={!communityData.isBrochure} className="px-8">
        <GeneratorHeader title="Customize Brochure" />
        <section className="pb-12 border-b">
          <div className="flex items-center gap-6">
            <H2>Customize Cover Page</H2>
            <button
              className="font-aw-sans text-aw-blue-400 flex"
              onClick={toggleCover}
            >
              <span>
                {communityData.pdfShowCover
                  ? 'Hide Cover Page'
                  : 'Show Cover Page'}
              </span>
            </button>
          </div>
          <div
            className={
              communityData.pdfShowCover
                ? 'flex flex-col w-full gap-8 mt-8'
                : 'hidden'
            }
          >
            <div className="w-full relative">
              {communityData.isBrochure ? (
                <TextInput
                  label="Brochure Title (required)"
                  value={communityData.name}
                  onChange={handleCommunityNameChange}
                />
              ) : (
                <TextInput
                  label="Brochure Title (required)"
                  value={communityData.pdfName}
                  onChange={handlePdfNameChange}
                />
              )}
              <ErrorMessage show={validationErrors.name}>
                {validationErrors.name}
              </ErrorMessage>
            </div>
            <div className="flex flex-col gap-2">
              <div className="w-full flex items-center">
                <input
                  type="checkbox"
                  id="pdfDate"
                  checked={communityData.pdfShowDate}
                  onChange={toggleDate}
                  className="mr-2 h-4 w-4 text-black border-current bg-white focus:ring-blue-600"
                />
                <label htmlFor="pdfDate" className="font-aw-sans text-base">
                  Include date
                </label>
              </div>
              {communityData.isBrochure ? null : (
                <div className="w-full flex items-center">
                  <input
                    type="checkbox"
                    id="pdfCommunityName"
                    checked={communityData.pdfShowCommunityName}
                    onChange={toggleCommunityName}
                    className="mr-2 h-4 w-4 text-black border-current bg-white focus:ring-blue-600"
                  />
                  <label
                    htmlFor="pdfCommunityName"
                    className="font-aw-sans text-base"
                  >
                    Include community name
                  </label>
                </div>
              )}
            </div>
            <div className="">
              <FieldLabel>
                Cover Page Image (required){' '}
                <span className="ml-4 inline text-xs text-aw-gray-300">
                  3080x840 recommended
                </span>
              </FieldLabel>
              {/* replace galleryData when we have gallery data */}
              <ImageUploadOrSelect
                imageType="Cover Image"
                setImage={handleSetCoverImage}
                currentImage={
                  communityData?.pdfCoverImage ?? { id: null, url: null }
                }
                targetEntryId={communityData.id}
                tagsToAddToUploads={['hero']}
                imageTagToQuery="hero"
              />
              {communityData.pdfCoverImage !== null ? (
                <div
                  className="flex w-min cursor-pointer rounded-lg border py-2 px-4 text-aw-gray-700 hover:fill-red-400 hover:text-red-400 hover:border-red-400"
                  onClick={() => setConfirmDeleteOpen(true)}
                >
                  <DeleteIcon className="mr-3 fill-current" />
                  <span className="font-aw-sans">Delete</span>
                </div>
              ) : null}
              <ErrorMessage show={validationErrors.coverImage !== null}>
                {validationErrors.coverImage}
              </ErrorMessage>
            </div>
          </div>
        </section>
        <section className="py-12 border-b">
          <H2>Gallery Pages (optional)</H2>
          <p className="mt-6 font-aw-serif">
            {`The order of images indicates the order they will appear in the PDF.
            2 images per page, maximum of ${ALLOWED_PDF_GALLERY_LIMIT} images.`}
          </p>
          <div className="mt-2 flex items-center">
            <div className="inline-block pr-2">
              <ArrowsUpDownIcon className="h-6 w-6 fill-aw-gray-700" />
            </div>
            <span className="font-aw-serif">
              Drag and drop images to reorder.
            </span>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="gallery-list">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {communityData.pdfGallery.map((image, index) => (
                    <Draggable
                      key={index}
                      draggableId={'gallery-' + index}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="flex flex-col items-start gap-4 py-8 border-b"
                        >
                          <div className="flex justify-between items-center w-full">
                            <h3 className="font-aw-serif font-bold text-lg text-aw-blue-600">
                              Image {index + 1}
                            </h3>
                            <div
                              {...provided.dragHandleProps}
                              className="cursor-grab active:cursor-grabbing right-0 top-8 rounded-lg border p-1 hover:shadow-lg"
                            >
                              <ArrowsUpDownIcon className="h-8 w-8 fill-aw-gray-700" />
                            </div>
                          </div>

                          <PdfGeneratorGalleryCard
                            currentImage={image ?? { id: null, url: null }}
                            index={index}
                            communityId={id}
                            errors={validationErrors.gallery[index]}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="mt-8 flex">
            <div
              className="flex cursor-pointer"
              onClick={() => handleAddNewImage()}
            >
              <span className="font-aw-sans text-aw-gray-700">
                Add {communityData.pdfGallery.length ? 'another' : ''} image
              </span>
              <SquarePlusIcon className="ml-2 h-10 w-10 fill-aw-gray-700" />
            </div>
          </div>
        </section>
        <section className="py-12">
          <H2>Expectation Sheets (optional)</H2>
          <ul className="mt-6">
            {sortedExpectationSheets.length ? (
              sortedExpectationSheets.map((sheet, index) => {
                return (
                  <li className="flex items-center py-1" key={index}>
                    <input
                      type="checkbox"
                      id={`sheet-${index}`}
                      className="mr-2 h-4 w-4 text-black border-current bg-white focus:ring-blue-600"
                      value={sheet.id}
                      onChange={handleSetExpectationSheet}
                      defaultChecked={checkInitialExpectationSheets(sheet.id)}
                    />
                    <label
                      htmlFor={`sheet-${index}`}
                      className="font-aw-sans text-base"
                    >
                      {sheet.name}
                    </label>
                  </li>
                );
              })
            ) : (
              <li className="font-aw-sans text-base">
                <em>No current expectation sheets are currently available.</em>
              </li>
            )}
          </ul>
        </section>
        <div className="flex flex-col items-center">
          {communityData.isBrochure ? (
            <GeneratorNavigation
              nextText="Preview and Download"
              prevRoute={`/brochure/${id}/hardware`}
              nextRoute={`/brochure/${id}/preview`}
              validateFields={validateFields}
              dataLoaded={isCommunityLoaded}
            />
          ) : (
            <>
              <ButtonPrimary
                className="block"
                onClick={() =>
                  validateFields() ? handlePreviewAction() : null
                }
              >
                Preview PDF
              </ButtonPrimary>
              {errors ? (
                <p className="italic text-sm text-red-400 font-aw-sans mt-4">
                  {' '}
                  There was an error in your submission. Please check all fields
                  and resubmit.{' '}
                </p>
              ) : null}
            </>
          )}
        </div>
      </SizeConstrained>
    </Layout>
  );
}

export default PdfGeneratorCustomize;
