import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../components/LoadingSpinner';
import Error from '../components/Error';
import { GET_SINGLE_COMMUNITY } from '../graphql/queries/communities';
import { GET_EXPECTATION_SHEETS } from '../graphql/queries/pdf';
import { GET_CURRENT_USER_ID } from '../graphql/queries/users';
import { COMMUNITY_DATA_TEMPLATE } from '../constants/communityData';
import {
  extractSingleCommunitiesData,
  extractExpectationSheetsData,
  synchronizeOrphanedLevelsToUncategorized,
} from '../graphql/helpers';
import { useParams } from 'react-router-dom';
import { useCommunityRoomsQuery } from '../graphql/query-hooks/useCommunityRoomsQuery';
import { useCommunityCabinetLevelsQuery } from '../graphql/query-hooks/useCommunityCabinetLevelsQuery';
import { useCommunityHardwareLevelsQuery } from '../graphql/query-hooks/useCommunityHardwareLevelsQuery';
import { useCommunityPdfQuery } from '../graphql/query-hooks/useCommunityPdfQuery';

// NOTE: This duplicates some of the functionality of the Site Generator but
// is reduced to only data that is relevant to building the PDF.

function PdfGenerator() {
  const { id } = useParams();
  const { currentUser } = useOutletContext();
  const [expectationSheets, setExpectationSheets] = useState(null);
  const [isCommunityQueriesComplete, setIsCommunityQueriesComplete] =
    useState(false);
  const [isCommunityLoaded, setIsCommunityLoaded] = useState(false);
  const [isExpectationSheetsLoaded, setIsExpectationSheetsLoaded] =
    useState(false);

  // DUPE: of communityData, same as Generator
  const [communityData, setCommunityData] = useState({
    ...COMMUNITY_DATA_TEMPLATE
  });

  const {
    loading,
    error,
    data,
    refetch: refetchCommunityData,
  } = useQuery(GET_SINGLE_COMMUNITY, {
    variables: { id },
    skip: !id,
  });

  const { data: expectationSheetsData } = useQuery(GET_EXPECTATION_SHEETS, {
    variables: { id },
    errorPolicy: 'ignore',
    onCompleted: (data) => {
      setExpectationSheets(extractExpectationSheetsData(data));
    },
  });

  // Build pdf data
  const { data: communityPdfData } = useCommunityPdfQuery(
    id,
    (pdfData, rawData) => {
      setCommunityData((prevState) => ({
        ...prevState,
        pdfName: pdfData?.pdfName,
        pdfShowCover: pdfData?.pdfShowCover,
        pdfShowDate: pdfData?.pdfShowDate,
        pdfShowCommunityName: pdfData?.pdfShowCommunityName,
        pdfCoverImage: pdfData?.pdfCoverImage,
        pdfSelectedExpectationSheets: pdfData?.pdfSelectedExpectationSheets,
        pdfGallery: pdfData?.pdfGallery,
      }));
    }
  );

  // Build communityData
  const { data: communityRoomsData } = useCommunityRoomsQuery(
    id,
    (roomData, rawData) => {
      const roomsTotal = rawData?.community?.roomsCollection?.total;
      setCommunityData((prevState) => ({
        ...prevState,
        rooms: roomData?.rooms,
        uniqueCabinetSelections: roomData?.uniqueCabinetSelections,
        uniqueHardwareSelections: roomData?.uniqueHardwareSelections,
        roomsTotal:
          roomsTotal !== undefined ? roomsTotal : prevState?.roomsTotal,
      }));
    }
  );

  // Set community cabinetLevels
  const { data: communityCabinetLevelData } = useCommunityCabinetLevelsQuery(
    id,
    (cabinetLevelData, rawData) => {
      const total = rawData?.community?.cabinetLevelsCollection?.total;
      setCommunityData((prevState) => ({
        ...prevState,
        cabinetLevels: cabinetLevelData?.cabinetLevels,
        cabinetLevelsTotal:
          total !== undefined ? total : prevState?.cabinetLevelsTotal,
      }));
    }
  );

  // Set hardware levels
  const { data: communityHardwareLevelData } = useCommunityHardwareLevelsQuery(
    id,
    (hardwareLevelData, rawData) => {
      const total = rawData?.community?.hardwareLevelsCollection?.total;
      setCommunityData((prevState) => ({
        ...prevState,
        hardwareLevels: hardwareLevelData?.hardwareLevels,
        hardwareLevelsTotal:
          total !== undefined ? total : prevState?.hardwareLevelsTotal,
      }));
    }
  );

  // Fetch user id for the image uploader - WOOD-422
  const { loading: userIdLoading } = useQuery(GET_CURRENT_USER_ID, {
    variables: { email: currentUser.email },
    errorPolicy: 'ignore',
    onCompleted: (data) => {
      setCommunityData({
        ...communityData,
        userId: data.userCollection.items[0].sys.id,
      });
    },
  });

  useEffect(() => {
    // Sets community as fully loaded or not as API calls finish.
    const roomsLoaded =
      communityData?.roomsTotal !== null &&
      communityData?.roomsTotal <= communityData?.rooms?.length;
    const cabinetLevelsLoaded =
      communityData?.cabinetLevelsTotal !== null &&
      communityData?.cabinetLevelsTotal <=
        Object.values(communityData?.cabinetLevels)?.length;
    const hardwareLevelsLoaded =
      communityData?.hardwareLevelsTotal !== null &&
      communityData?.hardwareLevelsTotal <=
        Object.values(communityData?.hardwareLevels)?.length;
    const expectationSheetsLoaded = expectationSheets !== null;

    const isLoadedResult =
      !loading &&
      communityData?.communityDetailsLoaded &&
      roomsLoaded &&
      cabinetLevelsLoaded &&
      hardwareLevelsLoaded &&
      expectationSheetsLoaded;
    setIsCommunityQueriesComplete(isLoadedResult);
  }, [
    communityData.rooms,
    communityData.roomsTotal,
    communityData.cabinetLevels,
    communityData.cabinetLevelsTotal,
    communityData.hardwareLevels,
    communityData?.hardwareLevelsTotal,
    communityData?.communityDetailsLoaded,
    expectationSheets,
    loading,
  ]);

  useEffect(() => {
    // Community fully loaded, final synchronization and data checks.
    if (!isCommunityQueriesComplete) {
      return;
    }

    const { cabinetLevelsObj, hardwareLevelsObj } =
      synchronizeOrphanedLevelsToUncategorized(
        communityData.uniqueHardwareSelections,
        communityHardwareLevelData,
        communityData.uniqueCabinetSelections,
        communityCabinetLevelData
      );

    setCommunityData((prevState) => ({
      ...prevState,
      cabinetLevelsObj: cabinetLevelsObj,
      hardwareLevelsObj: hardwareLevelsObj,
    }));
    setIsCommunityLoaded(true);
  }, [
    isCommunityQueriesComplete,
    communityCabinetLevelData,
    communityHardwareLevelData,
    communityData.uniqueCabinetSelections,
    communityData.uniqueHardwareSelections,
  ]);

  useEffect(() => {
    if (data) {
      const formattedData = extractSingleCommunitiesData(data);

      setCommunityData((prevState) => {
        return {
          ...prevState,
          ...formattedData,
          id,
          communityDetailsLoaded: true,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isLoading = loading || !isCommunityLoaded;
  const hasError = error;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (hasError) {
    return <Error />;
  }

  const generatorContext = {
    communityData,
    setCommunityData,
    expectationSheets,
    currentUser,
    refetchCommunityData,
    isCommunityLoaded,
  };

  return <Outlet context={generatorContext} />;
}

export default PdfGenerator;
