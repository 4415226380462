import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  PDFViewer,
  PDFDownloadLink,
  Font,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
// eslint-disable-next-line, used in pdf to image function
import PdfjsWorker from 'pdfjs-dist/build/pdf.worker';
import { GET_SELECTED_PRODUCTS } from '../../graphql/queries/products';
import DomineRegular from '../../assets/pdf-fonts/Domine-Regular.ttf';
import DomineBold from '../../assets/pdf-fonts/Domine-Bold.ttf';
import ButtonSecondary from '../../components/buttons/ButtonSecondary';
import logoImg from '../../assets/images/logo.png';

// pdf-react requires ttf font formats
Font.register({
  family: 'Domine',
  fonts: [
    {
      src: DomineRegular,
      fontWeight: 'normal',
    },
    {
      src: DomineBold,
      fontWeight: 'bold',
    },
  ],
});

// Data Helper Functions / Filters
function getItemData(allItemData, id) {
  let individualItemData = allItemData?.filter((itemData) => {
    return itemData.sys.id === id;
  });
  return individualItemData ? individualItemData[0] : null;
}

function filterProductsByLine(communityData, productLevels, productData) {
  if (productLevels.length === 0 || productData === undefined) {
    return null;
  }

  return Object.keys(productLevels).map((key) => {
    const currentLevel = productLevels[key];

    // exclude uncategorized levels, and levels with no products
    if (
      currentLevel.title === 'Uncategorized' ||
      currentLevel.ids.length === 0
    ) {
      return null;
    }

    // otherwise sort the level products by product lines
    const currentLevelProducts = currentLevel.ids.map((id) => {
      return getItemData(productData?.productCollection?.items, id) ?? {};
    });

    const productLines = currentLevelProducts.map((product) => {
      return product.productLine.name ? product.productLine.name : null;
    });
    const uniqueProductLines = [...new Set(productLines)];
    const productsByLines = uniqueProductLines.map((line) => {
      let matchedProduct = currentLevelProducts.filter((item) => {
        return item.productLine.name === line;
      });
      return matchedProduct ? matchedProduct : null;
    });

    return productsByLines;
  });
}

function mapLevels(levelData) {
  return Object.keys(levelData).map((key) => {
    const currentLevel = levelData[key];
    return {
      title: currentLevel.title ?? null,
      description: currentLevel.description ?? null,
    };
  });
}

function formatDate(date) {
  return date.toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric',
  });
}

async function PDFtoIMG(url) {
  return new Promise(async (resolve, reject) => {
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const fileArray = new Uint8Array(existingPdfBytes);
    const doc = await pdfjsLib.getDocument({
      data: fileArray,
      useSystemFonts: true,
    }).promise;

    const pages = [];
    const count = 1;

    for (let i = 1; i < doc.numPages + 1; i++) {
      const page = await doc.getPage(i);
      const viewport = page.getViewport({ scale: 2.5 });
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const task = page.render({ canvasContext: ctx, viewport: viewport });
      task.promise.then(() => {
        pages.push(canvas.toDataURL());
        if (count === doc.numPages) {
          resolve(pages);
        }
      });
    }
  });
}

// Shared PDF styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontFamily: 'Domine',
    backgroundColor: '#ffffff',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '18px',
  },
  gallery: {
    flexDirection: 'column',
    fontFamily: 'Domine',
    backgroundColor: '#ffffff',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: '18px',
    padding: '18px',
    flexWrap: 'none',
    position: 'relative',
  },
  level: {
    flexDirection: 'column',
    fontFamily: 'Domine',
    backgroundColor: '#ffffff',
    padding: '18px',
  },
  section: {
    margin: 0,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  expectationSheet: {
    margin: 0,
    padding: 0,
    position: 'relative',
    fontFamily: 'Domine',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
});

// PDF Page Components
function PageFooter() {
  return (
    <View
      style={{
        position: 'absolute',
        right: '18px',
        bottom: '24px',
        left: '18px',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
      fixed
    >
      <Image src={logoImg} style={{ height: '20px' }} />
      <Text
        style={{ fontSize: 8 }}
        render={({ pageNumber }) => `${pageNumber}`}
      />
    </View>
  );
}

function CoverPage({ communityData, today }) {
  if (!communityData.pdfShowCover) {
    return null;
  }

  return (
    <Page size="LETTER" style={styles.page}>
      <View style={styles.section}>
        <Text
          style={{
            textTransform: 'uppercase',
            fontSize: '36px',
            marginBottom: '10px',
          }}
        >
          {communityData.isBrochure
            ? communityData.name
            : communityData.pdfName}
        </Text>
        {communityData.pdfShowCommunityName && !communityData.isBrochure ? (
          <Text style={{ fontWeight: 'bold', fontSize: '12px' }}>
            {communityData.name}
          </Text>
        ) : null}
        {communityData.pdfShowDate ? (
          <Text style={{ fontWeight: 'bold', fontSize: '12px' }}>{today}</Text>
        ) : null}
      </View>
      <CoverImage communityData={communityData} />
      <View style={styles.section}>
        <Text style={{ textTransform: 'uppercase', fontSize: '10px' }}>
          Featuring Timberlake Cabinetry
        </Text>
        <View style={{ width: '300px', height: '60px', marginTop: '25px' }}>
          <Image
            src={communityData.builder.logo}
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </View>
      </View>
    </Page>
  );
}

function CoverImage({ communityData }) {
  if (communityData.pdfHideCover || communityData.pdfCoverImage.url === null) {
    return null;
  }

  return (
    <View
      style={{
        width: '100%',
        height: '40%',
        overflow: 'hidden',
        backgroundColor: 'cyan',
        marginTop: '30px',
        marginBottom: '30px',
      }}
    >
      {/* Image constrained to 1200px wide via Contentful Images API parameters */}
      <Image
        src={communityData.pdfCoverImage.url + '?w=1200'}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    </View>
  );
}

function GallerySheets({ communityData }) {
  if (communityData.pdfGallery.length === 0) {
    return null;
  }

  // ensure no null images make it into the layout - WOOD-432
  const scrubbedNullImages = communityData.pdfGallery.filter(
    (item) => item.image.url !== null
  );

  const pairedImages = scrubbedNullImages.reduce(function (
    result,
    value,
    index,
    array
  ) {
    if (index % 2 === 0) result.push(array.slice(index, index + 2));
    return result;
  },
  []);

  return pairedImages.map((image) => {
    if (image.length > 1) {
      return (
        <Page size="LETTER" style={styles.gallery}>
          {/* 46 is a 'magic' number to account for the 18px gap and page numbers since react-pdf doesn't allow calc() values */}
          <View style={{ width: '100%', height: '44%', flexShrink: '1' }}>
            {/* Image constrained to 1200px wide via Contentful Images API parameters */}
            <Image
              src={image[0].image.url + '?w=1200'}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </View>
          <View style={{ width: '100%', height: '44%', flexShrink: '1' }}>
            {/* Image constrained to 1200px wide via Contentful Images API parameters */}
            <Image
              src={image[1].image.url + '?w=1200'}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </View>
          <PageFooter />
        </Page>
      );
    } else {
      return (
        <Page size="LETTER" style={styles.gallery}>
          <View style={{ width: '100%', height: '44%' }}>
            {/* Image constrained to 1200px wide via Contentful Images API parameters */}
            <Image
              src={image[0].image.url + '?w=1200'}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </View>
          <PageFooter />
        </Page>
      );
    }
  });
}

function ProductSheets({ communityData, productsByLevelAndLine, levelData }) {
  if (productsByLevelAndLine === null) {
    return null;
  }

  return productsByLevelAndLine.map((level, index) => {
    if (level === null) {
      return null;
    }

    const currentLevel = levelData[index];

    return level.map((line, index) => {
      return (
        <Page size="LETTER" style={styles.level} key={index}>
          <View style={{ marginBottom: '24px' }} fixed>
            <View
              style={{
                width: '100%',
                borderBottom: '1px solid black',
                paddingBottom: '8px',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Text
                style={{
                  fontSize: '32px',
                  fontWeight: 'bold',
                  flexGrow: '1',
                }}
              >
                {currentLevel.title}
              </Text>

              <View
                style={{
                  maxHeight: '30px',
                  textAlign: 'right',
                }}
              >
                <Image
                  src={communityData.builder.logo}
                  style={{ width: 'auto', height: '100%' }}
                />
              </View>
            </View>
            {currentLevel.description ? (
              <Text style={{ fontSize: '12px', marginTop: '10px' }}>
                {currentLevel.description}
              </Text>
            ) : null}
          </View>
          <View
            style={{
              textAlign: 'center',
              marginBottom: '24px',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <Text
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                padding: '0 8px 2px 8px',
                borderBottom: '1px solid black',
              }}
            >
              {line[0].productLine.name}
            </Text>
          </View>
          <View
            style={{
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '18px',
              width: '100%',
              padding: '18px 18px 0 18px',
            }}
          >
            {line.map((product, index) => {
              return (
                <View style={{ width: '92px' }} key={index}>
                  {/* Image constrained to 400px wide via Contentful Images API parameters */}
                  {product.image ? (
                    <Image src={product.image.url + '?w=400'} />
                  ) : null}
                  <Text
                    style={{
                      fontSize: '8px',
                      marginTop: '4px',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                    }}
                  >
                    {product.wood + ' ' + product.finish}
                  </Text>
                </View>
              );
            })}
          </View>
          <PageFooter />
        </Page>
      );
    });
  });
}

function ExpectationSheets({ images }) {
  if (images.length === 0) {
    return null;
  }

  return images.map((image) => {
    return (
      <Page size="LETTER" style={styles.expectationSheets}>
        <Image src={image[0]} />
      </Page>
    );
  });
}

function PdfGeneratorPreview() {
  const { id } = useParams();
  const { communityData, expectationSheets } = useOutletContext();
  const downloadName = communityData.pdfName || communityData.name;

  // get cabinet data
  const { data: selectedCabinetData } = useQuery(GET_SELECTED_PRODUCTS, {
    variables: { ids: communityData.uniqueCabinetSelections },
  });

  const productsByLevelAndLine = useMemo(
    () =>
      filterProductsByLine(
        communityData,
        communityData.cabinetLevels,
        selectedCabinetData
      ),
    [communityData, selectedCabinetData]
  );

  const levelData = useMemo(
    () => mapLevels(communityData.cabinetLevels),
    [communityData]
  );

  // get hardware data
  const { data: selectedHardwareData } = useQuery(GET_SELECTED_PRODUCTS, {
    variables: { ids: communityData.uniqueHardwareSelections },
  });

  const hardwareByLevelAndLine = useMemo(
    () =>
      filterProductsByLine(
        communityData,
        communityData.hardwareLevels,
        selectedHardwareData
      ),
    [communityData, selectedHardwareData]
  );

  const hardwareLevelData = useMemo(
    () => mapLevels(communityData.hardwareLevels),
    [communityData]
  );

  const navigate = useNavigate();

  // react-pdf cannot currently embed other pdfs, so turn the pdf urls into
  // images to allow inclusion in the generated pdfs
  const matchedSheetUrls = communityData.pdfSelectedExpectationSheets.map(
    (id) => {
      return expectationSheets
        .filter((sheet) => {
          return sheet.id === id;
        })
        .map((sheet) => sheet.pdf.url);
    }
  );

  const [pdfImages, setPdfImages] = useState([]);

  useEffect(() => {
    Promise.all(
      matchedSheetUrls.map(
        (sheet) =>
          new Promise((resolve) => {
            PDFtoIMG(sheet).then(resolve);
          })
      )
    )
      .then((finalResults) => {
        setPdfImages(finalResults);
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GeneratedPdf = () => (
    <Document>
      <CoverPage communityData={communityData} today={formatDate(new Date())} />
      <ProductSheets
        communityData={communityData}
        productsByLevelAndLine={productsByLevelAndLine}
        levelData={levelData}
      />
      <ProductSheets
        communityData={communityData}
        productsByLevelAndLine={hardwareByLevelAndLine}
        levelData={hardwareLevelData}
      />
      <GallerySheets communityData={communityData} />
      <ExpectationSheets images={pdfImages} />
    </Document>
  );

  return (
    <>
      <PDFViewer
        className="h-[calc(100dvh-192px)] mt-[96px] w-full mx-auto"
        showToolbar={false}
      >
        <GeneratedPdf />
      </PDFViewer>
      <div className="fixed flex justify-center bottom-0 left-0 right-0 bg-white py-5 px-8 border-t">
        <div className="flex w-full max-w-[1472px] justify-between">
          <ButtonSecondary
            onClick={() => {
              navigate(
                communityData.isBrochure
                  ? `/brochure/${id}/customize`
                  : `/pdf/${id}/customize`,
                {
                  state: { id: communityData.id },
                }
              );
            }}
          >
            Return to Editing
          </ButtonSecondary>

          {/* DUPE: ButtonPrimary styles, but in this special PDF component */}
          <PDFDownloadLink
            document={<GeneratedPdf />}
            fileName={downloadName + '.pdf'}
            className="flex h-[55px] items-center shadow-md rounded py-2 px-3 font-aw-sans font-bold text-white bg-aw-blue-400 hover:opacity-90 lg:py-4 lg:px-6"
          >
            Download
          </PDFDownloadLink>
        </div>
      </div>
    </>
  );
}

export default PdfGeneratorPreview;
