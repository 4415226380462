import { useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { FieldLabel, TextInput } from '../components/forms';
import { GeneratorBuilderCard } from '../components/generator';
import { SizeConstrained } from '../components/layout/Layout';
import {
  GET_COMMUNITIES_BY_BUILDER,
  GET_SINGLE_COMMUNITY,
} from '../graphql/queries/communities';
import { GET_BUILDERS } from '../graphql/queries/builders';
import { GET_CURRENT_USER_ID } from '../graphql/queries/users';
import { CLONE_COMMUNITY } from '../constants/contentfulActions';
import { H1 } from '../components/typography';
import ButtonTertiary from '../components/buttons/ButtonTertiary';
import Speedbump from '../components/modals/Speedbump';
import {
  checkCommunityNameUnique,
  extractBuildersData,
  extractCommunityNamesFromBuilders,
} from '../graphql/helpers';
import LoadingSpinner from '../components/LoadingSpinner';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { useContentful } from '../hooks/useContentful';
import slugify from 'slugify';
import ErrorMessage from '../components/ErrorMessage';
import PublishStatusModal from '../components/modals/PublishStatusModal';

function CloneCommunity() {
  const { id } = useParams();
  const [communityName, setCommunityName] = useState('');
  const [userId, setUserId] = useState(null);
  const [builder, setBuilder] = useState(null);
  const [isCommunityNameValid, setIsCommunityNameValid] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [currentExistingCommunityNames, setCurrentExistingCommunityNames] =
    useState([]);
  const [dispatchCloneCommunity, cloneCommunityState] = useContentful();
  // used for the builder picker
  const [allBuildersData, setAllBuildersData] = useState([]);
  const { currentUser } = useOutletContext();
  const [isInProgress, setIsInProgress] = useState(false);
  const navigate = useNavigate();

  // get the source community data
  const { loading: isSourceCommunityLoading, data: sourceCommunity } = useQuery(
    GET_SINGLE_COMMUNITY,
    {
      variables: { id: id },
      skip: !id,
    }
  );

  // get all builder communities for the selected builder
  const { loading: isBuilderCommunitiesLoading, data: builderCommunitiesData } =
    useQuery(GET_COMMUNITIES_BY_BUILDER, {
      variables: { builderId: sourceCommunity?.community.builder.sys.id },
      skip: !sourceCommunity,
    });

  // set the builder community names after builder community data has loaded
  useEffect(() => {
    if (!isBuilderCommunitiesLoading) {
      setCurrentExistingCommunityNames(
        extractCommunityNamesFromBuilders(builderCommunitiesData)
      );
    }
  }, [isBuilderCommunitiesLoading, builderCommunitiesData]);

  const handleRemoveBuilder = () => {
    setBuilder(null);
    setCommunityName('');
  };

  const { loading: buildersLoading, data: buildersData } = useQuery(
    GET_BUILDERS,
    {
      variables: { email: currentUser.email },
      skip: currentUser === null,
    }
  );

  // after builders data is loaded, extract the builder data
  useEffect(() => {
    if (buildersData) {
      setAllBuildersData(extractBuildersData(buildersData));
    }
  }, [buildersData]);

  const { loading: userIdLoading } = useQuery(GET_CURRENT_USER_ID, {
    variables: { email: currentUser.email },
    errorPolicy: 'ignore',
    onCompleted: (data) => {
      setUserId(data.userCollection.items[0].sys.id);
    },
  });

  // set the builder and community name after source community data has loaded
  useEffect(() => {
    if (sourceCommunity) {
      setBuilder(sourceCommunity.community.builder);
    }
  }, [sourceCommunity]);

  useEffect(() => {
    if (currentExistingCommunityNames?.length) {
      const isValidCommunityName = checkCommunityNameUnique(
        communityName,
        currentExistingCommunityNames
      );
      if (isValidCommunityName) {
        setIsCommunityNameValid(
          isValidCommunityName &&
            communityName !== sourceCommunity.community.name
        );
      }
    }
  }, [communityName, currentExistingCommunityNames, sourceCommunity]);

  // upon success of a cloning action, navigate to the new community
  useEffect(() => {
    if (cloneCommunityState.success && cloneCommunityState.data) {
      setIsInProgress(false);
      navigate(`/generator/${cloneCommunityState.data.sys.id}/overview`);
    }
  }, [cloneCommunityState.success, navigate, cloneCommunityState.data]);

  useEffect(() => {
    setIsCommunityNameValid(
      checkCommunityNameUnique(communityName, currentExistingCommunityNames)
    );
  }, [communityName, currentExistingCommunityNames]);

  const handleCloneNewCommunity = () => {
    setConfirmModalOpen(false);
    setIsInProgress(true);
    dispatchCloneCommunity({
      type: CLONE_COMMUNITY,
      payload: {
        title: communityName,
        builderId: builder.sys.id,
        builderTagExists:
          builder.sys.id === sourceCommunity.community.builder.sys.id,
        targetBuilderTag: slugify(builder.name, { lower: true, strict: true }),
        sourceCommunityId: sourceCommunity.community.sys.id,
      },
      userId,
    });
  };

  if (buildersLoading || userIdLoading) return <LoadingSpinner />;

  return (
    <>
      {isSourceCommunityLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {isInProgress && (
            <PublishStatusModal
              show={cloneCommunityState.loading}
              loadType={'clone'}
              error={cloneCommunityState.error}
              publishComplete={cloneCommunityState.success}
              url={cloneCommunityState.data?.fields?.url}
              communityName={communityName}
            />
          )}
          <SizeConstrained>
            <div className="flex flex-col items-center w-full pt-12">
              <div className="h-16"></div>
              <H1 className="p-6">
                Cloning from{' '}
                <span className="italic">{sourceCommunity.community.name}</span>
              </H1>

              <img
                src={
                  sourceCommunity.community.banner?.url ??
                  sourceCommunity.community.pdfCoverImage?.url
                }
                alt={`Community Banner for ${sourceCommunity.community.name}`}
                className="w-1/2 h-32 object-cover rounded-md"
              />
              <div className="h-12"></div>
            </div>

            <div className="flex w-full gap-12">
              <div className={`${builder === null ? 'w-1/4' : 'w-full'}`}>
                <FieldLabel>Builder:</FieldLabel>

                {builder !== null && (
                  <GeneratorBuilderCard
                    builder={builder}
                    isSelected={true}
                    isNewCommunity={false}
                    handleRemoveBuilder={handleRemoveBuilder}
                    setBuilder={setBuilder}
                  />
                )}
              </div>
              {builder !== null && (
                <div className="w-full relative placeholder-red-400">
                  <TextInput
                    label="New Community Name"
                    value={communityName}
                    placeholder={`Clone of ${sourceCommunity.community.name}`}
                    onChange={(e) => {
                      setCommunityName(e.target.value);
                    }}
                    additionalInputClassNames="placeholder-neutral-400 placeholder:italic"
                  />

                  <ErrorMessage show={!isCommunityNameValid}>
                    The current community name is already in use or is invalid.
                    Please choose a different one.
                  </ErrorMessage>
                </div>
              )}
            </div>
            <div className="flex justify-end mt-8">
              <ButtonTertiary
                onClick={() => setConfirmModalOpen(true)}
                disabled={
                  !isCommunityNameValid || !builder || communityName === ''
                }
              >
                Clone{' '}
                {sourceCommunity.community.isBrochure
                  ? 'Brochure'
                  : 'Microsite'}{' '}
                <ArrowRightIcon className="ml-4 h-5 w-5" />
              </ButtonTertiary>
            </div>
          </SizeConstrained>

          <Speedbump
            open={confirmModalOpen}
            setOpen={setConfirmModalOpen}
            onConfirm={handleCloneNewCommunity}
            onClose={() => setConfirmModalOpen(false)}
            confirmTitle="Ready to clone?"
            confirmBody="Once cloning begins, please wait. You will be redirected to the new community once it's ready to customize."
            confirmBtnText="Continue"
            warning={false}
          />
        </>
      )}
    </>
  );
}

export default CloneCommunity;
