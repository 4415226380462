import React, { Fragment, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { belongsToHardware, existsElsewhere } from './GeneratorRoomBuilder';
import CheckmarkIcon from '../icons/CheckMark';
import Speedbump from '../modals/Speedbump';

function GeneratorAccordionItem({
  productFamily,
  indexInCommunityData,
  disabled,
}) {
  const {
    communityData,
    setCommunityData,
    productsList,
    removedRecommendations,
    setRemovedRecommendations,
  } = useOutletContext();
  const [previewImages, setPreviewImages] = useState({});
  const [productToToggle, setProductToToggle] = useState({});
  const [showRecommendationRemoveModal, setShowRecommendationRemoveModal] =
    useState(false);
  const previewImageMods =
    productFamily.name === 'hardware'
      ? `?w=80&q=90&fit=scale&f=bottom&fm=jpg`
      : `?w=200&q=90&&f=bottom_left&fit=scale&fm=jpg&q=85`;
  
  const handleProductToggle = (
    { id, image },
    productLine,
    overrideRecommendationCheck = false
  ) => {
    setProductToToggle({ id, image, productLine });

    let newCommunityData = { ...communityData };

    let newSelections = null;
    let newUniqueProductSelections = [];
    let newLevelData = {};

    let selectionsKey = '';
    let levelDataKey = '';

    if (belongsToHardware(productsList, id)) {
      selectionsKey = 'uniqueHardwareSelections';
      levelDataKey = 'hardwareLevels';
    } else {
      selectionsKey = 'uniqueCabinetSelections';
      levelDataKey = 'cabinetLevels';
    }

    // a recommendation for the item being toggled exists
    const foundRecommendation = communityData.recommendations.find(
      (recommendation) => recommendation.product.id === id
    );
    if (communityData.rooms[indexInCommunityData].products.includes(id)) {
      // present in room - removing logic

      // if not present in other rooms, remove from selections
      if (!existsElsewhere(communityData, indexInCommunityData, id)) {
        if (foundRecommendation) {
          if (!overrideRecommendationCheck) {
            setShowRecommendationRemoveModal(true);
            // bail out and show the speedbump
            return;
          } else {
            // function is being called from the confirm button in the speedbump
            setRemovedRecommendations((prev) => [...prev, foundRecommendation]);
            const newRecommendations = communityData.recommendations.filter(
              (recommendation) => {
                return recommendation.product.id !== id;
              }
            );
            newCommunityData.recommendations = newRecommendations;
          }
        }

        newUniqueProductSelections = communityData[selectionsKey].filter(
          (selection) => {
            return selection !== id;
          }
        );
        Object.keys(communityData[levelDataKey]).forEach((key) => {
          newLevelData[key] = {
            title: communityData[levelDataKey][key].title,
            description: '',
            ids: communityData[levelDataKey][key].ids.filter((idInLevel) => {
              return idInLevel !== id;
            }),
          };
        });
      } else {
        newUniqueProductSelections = communityData[selectionsKey];
        newLevelData = communityData[levelDataKey]
          ? communityData[levelDataKey]
          : {};
      }

      // remove from room selections
      newSelections = communityData.rooms[indexInCommunityData].products.filter(
        (selection) => selection !== id
      );
    } else {
      // not present in room -- adding logic
      newUniqueProductSelections = communityData[selectionsKey]
        ? communityData[selectionsKey]
        : [];
      newLevelData = { ...communityData[levelDataKey] };

      if (!newUniqueProductSelections.includes(id)) {
        newUniqueProductSelections.push(id);
        let uncategorizedKey = Object.keys(newLevelData).filter((key) => {
          return newLevelData[key].title === 'Uncategorized';
        })[0];
        newLevelData[uncategorizedKey].ids.push(id);
      }

      // add to room selections
      newSelections = [
        ...communityData.rooms[indexInCommunityData].products,
        id,
      ];
      const removedRecommendationIndex = removedRecommendations?.findIndex(
        (recommendation) => recommendation.product.id === id
      );
      // If a removed recommendation is found, add it back to the recommendations array
      if (removedRecommendationIndex && removedRecommendationIndex !== -1) {
        newCommunityData.recommendations = [
          ...communityData.recommendations,
          removedRecommendations[removedRecommendationIndex],
        ];
        // Remove the recommendation from the removedRecommendations state
        setRemovedRecommendations((prev) =>
          prev.filter((_, index) => index !== removedRecommendationIndex)
        );
      }
    }

    newCommunityData.rooms[indexInCommunityData].products = newSelections;
    newCommunityData[selectionsKey] = newUniqueProductSelections;
    newCommunityData[levelDataKey] = newLevelData;

    setCommunityData(newCommunityData);

    // if any other rooms in communityData.rooms have this room's id set to mirrored, update the selections for those rooms as well
    communityData.rooms.forEach((room, index) => {
      if (room.mirroring === communityData.rooms[indexInCommunityData].id) {
        const newCommunityData = [...communityData.rooms];
        newCommunityData[index].products = newSelections;
        setCommunityData({ ...communityData, rooms: newCommunityData });
      }
    });

    // set the preview image for this product line if the item is not already selected
    if (newSelections.includes(id)) {
      setPreviewImages((prevImages) => ({
        ...prevImages,
        [productLine.id]: image,
      }));
    }
  };

  // Toggle all products within a product line
  const handleProductToggleAll = (event) => {    

    const productsContainer = event.target.closest('.product-line');
    const items = productsContainer.querySelectorAll('.item-product');
    const selectedItems = productsContainer.querySelectorAll('.item-selected');

    if (items.length === selectedItems.length) {

      // deselect all
      selectedItems.forEach(item => {
        item.click();
      });

    } else {

      // select all
      items.forEach(item => {
        if (!item.classList.contains('item-selected')) {
          item.click();
        }
      });

    }

  };

  useEffect(() => {
    const images = {};
    productFamily.productLines.forEach((productLine) => {
      if (
        productLine &&
        productLine.products[0] &&
        productLine.products[0].image &&
        !previewImages[productLine.id]
      ) {
        images[productLine.id] = productLine.products[0].image;
      }
    });

    setPreviewImages((prevImages) => ({ ...prevImages, ...images }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmRemoveRecommended = () => {
    if (productToToggle) {
      const { id, image, productLine } = productToToggle;
      const product = { id, image };
      // pass "true" as third param to bypass the recommendation check
      handleProductToggle(product, productLine, true);
      setShowRecommendationRemoveModal(false);
    }
  };

  return (
    <>
      <Speedbump
        open={showRecommendationRemoveModal}
        setOpen={setShowRecommendationRemoveModal}
        onConfirm={() => handleConfirmRemoveRecommended()}
        confirmTitle="Remove product with recommendation?"
        confirmBtnText={'Remove product'}
        confirmBody={
          <>
            <span>
              This product has a recommendation associated with it. Because no
              other rooms contain this product, removing it will also remove the
              recommendation. Are you sure you want to continue?
            </span>
            <span className="mt-4 italic block">
              (note: If you add this product back in this session, your
              recommendation will be restored)
            </span>
          </>
        }
        onClose={() => {
          setShowRecommendationRemoveModal(false);
        }}
        warning={false}
      />
      <div className="ml-3">
        {productFamily.productLines.map((productLine, index) => {
          /* as we did in the parent component for the productFamily, we need to check 
        if the product line is empty to avoid rendering broken UI */
          const isEmpty = productLine.products.length === 0;

          return isEmpty ? (
            <Fragment key={productLine.id} />
          ) : (
            <div
              key={productLine.id}
              className={`${
                index !== productFamily.productLines.length - 1
                  ? 'border-b'
                  : ''
              } p-5 relative product-line`}
            >
              <div
                className={`${
                  disabled
                    ? 'bg-gray-200 absolute top-0 bg-opacity-40 left-0 h-full w-full z-10'
                    : ''
                } ...`}
              ></div>
              <h3 className="text-xl text-aw-blue-600 font-aw-serif mr-3">
                {productLine.name} 

                <button onClick={(event) => handleProductToggleAll(event)} className="toggle-button items-center rounded bg-aw-blue-400 py-2 px-3 mx-6 font-aw-serif shadow-md text-white text-sm hover:opacity-90 justify-center">
                  Select All
                </button> 
              </h3>
              <div className="flex py-2">
                <div className="pt-8">
                  <img
                    src={previewImages[productLine.id]}
                    className="w-44 object-contain"
                    alt={productFamily.formattedName}
                  />
                </div>
                <div className="ml-3  flex items-start gap-3 overflow-x-auto w-full flex-wrap p-1">
                  {productLine.products?.map((product, index) => {
                    const isSelected = communityData.rooms[
                      indexInCommunityData
                    ].products?.includes(product.id);

                    return (
                      <div
                        className={`${
                          isSelected ? 'item-selected bg-gray-200' : ''
                        } item-product hover:shadow-lg outline-gray-200 ${
                          productFamily.name !== 'hardware' ? 'w-60' : 'w-52'
                        } hover:outline-1 p-2 min-h-[120px] flex ${
                          !disabled ? 'cursor-pointer' : ''
                        } relative rounded mt-5`}
                        onClick={() => {
                          //  update mainPreviewImage to the selected product image
                          if (!disabled) {
                            handleProductToggle(product, productLine);
                          }
                        }}
                        key={product.productCode}
                      >
                        <div className="flex gap-2 justify-between items-start w-full">
                          <div className="ml-2 gap-1 flex flex-col justify-center font-aw-sans  ">
                            <span className="inline-block font-bold">
                              {product.productCode}
                            </span>
                            <p className="text-sm uppercase">{product.wood}</p>
                            <p className="text-sm uppercase">
                              {product.finish}
                            </p>
                          </div>
                          <img
                            className={
                              productFamily.name === 'hardware'
                                ? ''
                                : 'object-none min-w-[130px] h-[90px] min-h-[90px] object-left-bottom'
                            }
                            src={
                              product.image
                                ? `${product.image}${previewImageMods}`
                                : ''
                            }
                            alt={
                              product.wood
                                ? product.wood + ' ' + product.finish
                                : product.finish
                            }
                          />
                          {isSelected && (
                            <div className="absolute bg-green-500 rounded-full bottom-1 p-1 right-1">
                              <CheckmarkIcon
                                className={`text-white  stroke-[3]  h-6 w-6 ml-auto  ${
                                  productFamily.name === 'hardware' ? '' : ''
                                }`}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default GeneratorAccordionItem;
